<template>
    <div>
        <KTPortlet v-bind:title="'Services'" v-if="services ? services.length>0 : 0">
            <template v-slot:toolbar>
                <v-btn
                    @click="showDialog"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                    style="background:transparent;"
                >
                    <i class="flaticon-edit"></i>
                </v-btn>
            </template>
            <template v-slot:body>
                <div class="kt-notification kt-notification--fit">
                    <a
                        href="#"
                        class="kt-notification__item"
                        v-for="(service,i) in services"
                        :key="service.id"
                    >
                        <div class="kt-notification__item-icon" v-if="service.title">
                            <i class="kt-nav__link-icon fa fa-genderless kt-font-success"></i>
                        </div>
                        <div class="kt-notification__item-details" v-if="service.title">
                            <div class="kt-notification__item-title">
                                {{ service.title }}
                                <span class="pull-right">
                  <a href="#" @click="deleteService(service.id)">
                    <img src="@/assets/media/icons/send-to-trash.png" class="delete_icon"/>
                  </a>
                </span>
                            </div>
                        </div>
                    </a>
                </div>
            </template>
        </KTPortlet>

        <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower" v-else>
            <div class="kt-portlet__body">
                <div class="kt-iconbox__body">
                    <div class="kt-iconbox__icon">
                        <i class="flaticon-presentation kt-font-success kt-font-big"></i>
                    </div>
                    <div class="kt-iconbox__desc">
                        <h3 class="kt-iconbox__title">
                            <a class="kt-link" href="#">Services</a>
                        </h3>
                        <div class="kt-iconbox__content">
                            <a href="#" @click="showDialog">Add Services, Let Auidence Know What You Do</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="servicesialog" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Services</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="form-block">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-combobox
                                        v-model="servicesValue"
                                        :items="serviceItems"
                                        :search-input.sync="search"
                                        hide-selected
                                        label="Add Services"
                                        multiple
                                        item-text="title"
                                        item-value="id"
                                        persistent-hint
                                        small-chips
                                        outlined
                                        densed
                                    >
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        No results matching "
                                                        <strong>{{ search }}</strong>". Press
                                                        <kbd>enter</kbd> to create a new one
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-combobox>
                                    <p
                                        class="text-danger font-weight-bold"
                                        v-if="$v.servicesValue.$error"
                                    >Service is required</p>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- <pre>
                          {{ servicesValue }}
                        </pre>-->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                    <v-btn
                        color="white--text v-btn theme--light elevation-2 v-size--large primary"
                        class="btn-save-popup"
                        :loading="isBusy"
                        text
                        @click.prevent="addService"
                    >Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import OrganisationServiceService from "@/services/organisation/OrganisationServiceService";
    import {required} from "vuelidate/lib/validators";

    const services = new OrganisationServiceService();

    export default {
        name: "Services",
        props: ["slug", "services"],
        validations: {
            servicesValue: {required}
        },
        data: () => ({
            isBusy: false,
            servicesialog: false,
            servicesValue: "",
            search: null,
            serviceRequired: false,
            serviceItems: [],
            newservices: []
        }),
        components: {
            KTPortlet
        },
        mounted() {
            this.getServices();
        },
        methods: {
            getServices() {
                services
                    .getNotAssignedServices(this.slug)
                    .then(res => {
                        this.serviceItems = [];
                        this.serviceItems = res.data.data;
                    })
                    .catch(err => {
                    });
            },
            close() {
                this.servicesialog = false;
                this.$emit("initOrganisation");
            },
            showDialog() {
                this.servicesialog = true;
            },
            isObject(arg) {
                return arg !== null && typeof arg === "object";
            },
            addService() {
                this.$v.$touch();
                if (!this.$v.servicesValue.$error) {
                    this.$v.$reset();
                    this.isBusy = true;
                    this.servicesValue.filter((value, index) => {
                        if (this.isObject(value)) {
                            this.newservices.push({
                                title: value.title,
                                directory_service_id: value.id,
                                sort_position: 1,
                                is_active: 1
                            });
                        } else {
                            this.newservices.push({
                                title: value,
                                directory_service_id: null,
                                sort_position: 1,
                                is_active: 1
                            });
                        }
                    });
                    services
                        .saveMultiple(this.slug, this.newservices)
                        .then(res => {
                            this.servicesValue = "";
                            this.getServices();
                            this.$emit("initOrganisation");
                            this.servicesialog = false;
                            this.isBusy = false;
                            this.$snotify.success("Service added");
                        }).catch(err => {
                    }).finally(() => (this.isBusy = false));
                }
            },

            // Delete Faq
            deleteService(id) {
                this.$confirm(
                    {
                        message: `Confirm delete service ?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                services
                                    .delete(this.slug, id)
                                    .then(res => {
                                        this.getServices();
                                        this.$emit("initOrganisation");
                                        this.faqdialog = false;
                                        this.$snotify.success("Service deleted");
                                    })
                                    .catch(err => {
                                    });
                            }
                        }
                    }
                )

            }
        }
    };
</script>
