<template>
    <div>
        <KTPortlet v-bind:title="'Frequently Asked Questions (FAQ)'" v-if="faqs ? faqs.length>0 :0">
            <template v-slot:toolbar>
                <v-btn
                    @click="showDialog"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                    style="background:transparent;"
                >
                    <i class="flaticon-add-circular-button"></i>
                </v-btn>
            </template>
            <template v-slot:body>
                <v-app>
                    <v-expansion-panels focusable>
                        <v-expansion-panel v-for="(item,i) in faqs" :key="i" class="faq-list">
                            <v-expansion-panel-header class="font-weight-regular">
                                {{ item.title}}
                                <span class="text-right">
                  <!-- <i  v-if="item.is_active" class="flaticon2-check-mark kt-font-success"></i>
                  <i v-else class="flaticon2-exclamation kt-font-warning"></i>-->
                  <a href="javascript:void(0)" @click="editFaq(item)" class="ps-1">
                    <img src="@/assets/media/icons/edit-tools.png" class="edit_icon"/>
                  </a>
                  <a href="#" @click="deleteFaq(item.id)" class="ps-1">
                    <img src="@/assets/media/icons/send-to-trash.png" class="delete_icon"/>
                  </a>
                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="font-weight-regular">{{ item.description }}
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-app>
            </template>
        </KTPortlet>

        <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower" v-else>
            <div class="kt-portlet__body">
                <div class="kt-iconbox__body">
                    <div class="kt-iconbox__icon">
                        <i class="flaticon2-talk kt-font-success kt-font-big"></i>
                    </div>
                    <div class="kt-iconbox__desc">
                        <h3 class="kt-iconbox__title">
                            <a class="kt-link" href="#">Frequently Asked Questions (FAQs)</a>
                        </h3>
                        <div class="kt-iconbox__content">
                            <a href="#" @click="showDialog">Add Frequently Asked Question And Ease Your Audience</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="faqdialog" persistent max-width="900px">
            <v-card>
                <v-card-title>
                    <span v-if="!edit" class="headline">Add FAQ</span>
                    <span v-else class="headline">Update FAQs</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="form-block" v-for="(faq, index) in newfaqs" :key="index">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field label="Title*" v-model="faq.title" required outlined
                                                  densed></v-text-field>
                                    <p
                                        class="text-danger font-weight-bold"
                                        v-if="$v.newfaqs.$each[index].title.$error"
                                    >Title is required</p>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-textarea
                                        label="Description*"
                                        auto-grow
                                        row="1"
                                        row-height="30px"
                                        v-model="faq.description"
                                        required
                                        outlined
                                        densed
                                    ></v-textarea>
                                    <p
                                        class="text-danger font-weight-bold"
                                        v-if="$v.newfaqs.$each[index].description.$error"
                                    >Description is required</p>
                                </v-col>
                                <v-col cols="2" md="2">
                                    <v-text-field label="Position" v-model="faq.sort_position" required outlined
                                                  densed></v-text-field>
                                    <p
                                        class="text-danger font-weight-bold"
                                        v-if="$v.newfaqs.$each[index].sort_position.$error"
                                    >Position is required</p>
                                </v-col>
                                <v-col cols="2" md="2">
                                    <v-switch label="Active" v-model="faq.is_active"></v-switch>
                                </v-col>
                                <!-- <v-col cols="12" md="12">
                                  <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    x-small
                                    color="red"
                                    v-if="index!=0"
                                    @click="removeFaq(index)"
                                  >
                                    <v-icon dark>mdi-minus</v-icon>
                                  </v-btn>
                                  <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    x-small
                                    v-if="index===newfaqs.length-1 && !edit"
                                    color="green"
                                    @click="addFaqs"
                                  >
                                    <v-icon dark>mdi-plus</v-icon>
                                  </v-btn>
                                </v-col> -->
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                    <v-btn color="white--text v-btn theme--light elevation-2 v-size--large primary" :loading="isBusy"
                           class="btn-save-popup" text @click.prevent="saveOrUpdate">Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import NotifyService from "@/common/notify.service";
    import OgranisationFaqService from "@/services/organisation/OrgranisationFaqService";

    const ogranisationFaq = new OgranisationFaqService();
    export default {
        name: "Faq",
        props: ["faqs", "slug"],
        validations: {
            newfaqs: {
                required,
                $each: {
                    title: {required},
                    description: {required},
                    sort_position: {required}
                }
            }
        },
        data: () => ({
            isBusy: false,
            faqdialog: false,
            edit: false,
            newfaqs: [
                {
                    title: "",
                    description: "",
                    sort_position: 1,
                    is_active: 1
                }
            ]
        }),
        components: {
            KTPortlet
        },
        methods: {
            showDialog() {
                this.faqdialog = true;
            },
            close() {
                this.faqdialog = false;
                this.resetFaq();
                this.$emit("initOrganisation");
            },
            // Add Faq Form
            addFaqs() {
                this.newfaqs.push({
                    title: "",
                    description: "",
                    sort_position: 1,
                    is_active: 1
                });
            },
            editFaq(item) {
                if (item.id != null && item.id != undefined && item.id != "") {
                    this.faqdialog = true;
                    this.edit = true;
                    this.newfaqs = [];
                    this.newfaqs.push(item);
                }
            },

            // Remove Faq Form
            removeFaq(index) {
                this.newfaqs.splice(index, 1);
            },

            // Save Faq call api
            saveOrUpdate() {
                this.$v.$touch();
                if (this.$v.newfaqs.$error) {
                    setTimeout(() => {
                        this.$v.$reset();
                    }, 3000);
                } else {
                    this.isBusy = true;
                    if (!this.edit) {
                        this.save();
                    } else {
                        this.update();
                    }
                }
            },
            save() {
                ogranisationFaq
                    .saveMultiple(this.slug, this.newfaqs)
                    .then(res => {
                        this.resetFaq();
                        this.isBusy = false;
                        this.$emit("initOrganisation");
                        this.faqdialog = false;
                        this.$snotify.success("FAQ added");
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            update() {
                ogranisationFaq
                    .update(this.slug, this.newfaqs[0].id, this.newfaqs[0])
                    .then(res => {
                        this.resetFaq();
                        this.isBusy = false;
                        this.$emit("initOrganisation");
                        this.faqdialog = false;
                        this.edit = false;
                        this.$snotify.success("FAQ updated");
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            resetFaq() {
                this.newfaqs = [
                    {
                        title: "",
                        description: "",
                        sort_position: 1,
                        is_active: 1
                    }
                ];
            },

            // Delete Faq
            deleteFaq(id) {
                this.$confirm(
                    {
                        message: `Confirm delete FAQ`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                ogranisationFaq
                                    .delete(this.slug, id)
                                    .then(res => {
                                        this.$emit("initOrganisation");
                                        this.faqdialog = false;
                                        this.$snotify.success("FAQ deleted");
                                    })
                                    .catch(err => {

                                    });
                            }
                        }
                    }
                )

            }
        }
    };
</script>
