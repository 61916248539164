import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class organisationAddress {
    getAllAddresses() {
        let url = API_URL + 'user/addresses'
        return apiService.get(url);
    }
    getAddress(value) {
        let url = API_URL + 'user/address/search?key=' + value
        return apiService.query(url);
    }
    paginate(slug, index = null) {
        let url = API_URL + 'user/organisation/' + slug + '/address';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/organisation/' + slug + '/address'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/organisation/' + slug + '/address/' + id
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/organisation/' + slug + '/address/' + id + '/delete'
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/organisation/' + slug + '/address/' + id
        return apiService.get(url);
    }


    getOrganistionAddressesByUser(){
        let url = API_URL + 'user/addresses/user/organisation'
        return apiService.get(url);
    }

}
