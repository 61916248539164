<template>
    <div>
        <KTPortlet v-bind:title="'Description'" v-if="organisation.description">
            <template v-slot:toolbar>
                <v-btn
                    @click="showDialog"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                    style="background:transparent;"
                >
                    <i class="flaticon-edit"></i>
                </v-btn>
            </template>
            <template v-slot:body>
                <p v-html="organisation.description"></p>
            </template>
        </KTPortlet>

        <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower" v-else>
            <div class="kt-portlet__body">
                <div class="kt-iconbox__body">
                    <div class="kt-iconbox__icon">
                        <i class="flaticon2-writing kt-font-success kt-font-big"></i>
                    </div>
                    <div class="kt-iconbox__desc">
                        <h3 class="kt-iconbox__title">
                            <a class="kt-link" href="#">About Business</a>
                        </h3>
                        <div class="kt-iconbox__content">
                            <a href="#" @click="showDialog">Click here to Add Description</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="descriptionDialog" persistent max-width="900px">
            <v-card>
                <v-card-title>
                    <span class="headline">About Business</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form v-model="valid" ref="organisation" lazy-validation>
                            <div class="form-block">
                                <v-row>
                                    <v-col cols="12" md="12" offset-md="12">
                                        <ckeditor v-model="organisation.description" :config="editorConfig"></ckeditor>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-form>
                        <!-- <pre> {{organisation}}</pre> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="cancelDialog">Close</v-btn>
                    <v-btn color="white--text v-btn theme--light elevation-2 v-size--large primary"
                           class="btn-save-popup" :loading="isBusy" text @click.prevent="update">Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import OgranisationService from "@/services/organisation/OrganisationService";

    const ogranisationService = new OgranisationService();
    import CKEditor from "ckeditor4-vue";

    export default {
        name: "organisation-view-description",
        props: ["slug", "organisation"],
        data: () => ({
            isBusy: false,
            descriptionDialog: false,
            valid: false,
            editorConfig: {
                toolbar: [["Bold", "Italic", "Underline"]]
            }
        }),
        components: {
            KTPortlet,
            ckeditor: CKEditor.component
        },
        methods: {
            showDialog() {
                this.descriptionDialog = true;
            },
            cancelDialog() {
                this.$emit("initOrganisation");
                this.descriptionDialog = false;
            },
            update() {
                this.isBusy = true;
                let organisation = this.sanitizeOrganisation(this.organisation);
                ogranisationService
                    .update(this.slug, organisation)
                    .then(res => {
                        this.descriptionDialog = false;
                        this.$emit("initOrganisation");
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            sanitizeOrganisation(organisation) {
                delete organisation.user;
                delete organisation.addresses;
                delete organisation.services;
                delete organisation.attribute;
                delete organisation.categories;
                delete organisation.tags;
                delete organisation.faqs;
                delete organisation.awards;
                delete organisation.accepted_payment;
                delete organisation.accepted_payment;
                delete organisation.external_links;
                delete organisation.opening_hour;
                delete organisation.opening_hour_exceptions;
                return organisation;
            }
        }
    };
</script>
