import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class organisationverificationService {




    create(slug, data) {
        let url = API_URL + 'user/organisation/' + slug + '/verification'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/organisation/' + slug + '/verification/' + id
        return apiService.put(url, data);
    }

    show(slug, id) {
        let url = API_URL + 'user/organisation/' + slug + '/verification/' + id
        return apiService.get(url);
    }


}
