<template>
    <div>
        <KTPortlet v-bind:title="'Locations'" v-if="locations ? locations.length>0 :false">
            <template v-slot:toolbar>
                <v-btn
                    @click="openDialog"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                    style="background:transparent;"
                >
                    <i class="flaticon-add-circular-button"></i>
                </v-btn>
                <v-row justify="center"></v-row>
            </template>
            <template v-slot:body>
                <div class="kt-notes">
                    <div class="kt-notes__items">
                        <div class="kt-notes__item" v-for="(address,i) in locations" :key="address.id">
                            <div class="kt-notes__media">
                <span class="kt-notes__icon">
                  <i class="flaticon-placeholder-3 kt-font-brand"></i>
                </span>
                            </div>
                            <div class="kt-notes__content">
                                <div class="kt-notes__section">
                                    <div class="kt-notes__info">
                                        <a href="#" class="kt-notes__title">{{ address.address_line_1 }}</a>
                                    </div>
                                    <button
                                        @click="editLocation(address)"
                                        class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                    >
                                        <i class="flaticon-edit"></i>
                                    </button>
                                </div>
                                <span class="kt-notes__body">
                  <div class="row">
                    <div class="col-md-6">
                      <!--                      <br />-->
                        <!--                      {{ address.address_line_1 }},-->
                        <!--                      <br />-->
                      {{ address.address_line_2 }}
                      <br/>
                      {{ address.area }}
                      {{ address.city }} , {{ address.state }}
                    </div>
                    <div class="col-md-6">
                      <!--                      Lat : {{ address.geo_lat }},-->
                        <!--                      Long : {{ address.geo_long }},-->
                      <a target="_blank"
                         :href="'http://maps.google.com/maps?daddr='+address.geo_lat+','+address.geo_long"
                         class="btn btn-outline-brand btn-elevate btn-pill">
                        <i class="flaticon-placeholder-3"></i> Show On Map
                      </a>
                    </div>
                  </div>
                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </KTPortlet>

        <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower" v-else>
            <div class="kt-portlet__body">
                <div class="kt-iconbox__body">
                    <div class="kt-iconbox__icon">
                        <i class="flaticon-map-location kt-font-success kt-font-big"></i>
                    </div>
                    <div class="kt-iconbox__desc">
                        <h3 class="kt-iconbox__title">
                            <a class="kt-link" href="#">Find Multiple Location of Your Branches</a>
                        </h3>
                        <div class="kt-iconbox__content">
                            <a
                                href="#"
                                @click="openDialog"
                            >Add Multiple Locations and Let you Audience Reach you as Fastest as Possible</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="dialog" persistent max-width="650px">
            <v-card>
                <v-card-title>
                    <span class="headline">Business Location</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="model"
                                    :items="items"
                                    :loading="isLoading"
                                    :search-input.sync="search"
                                    hide-no-data
                                    hide-selected
                                    item-text="Description"
                                    item-value="area"
                                    @change="placechange"
                                    label="Find Your Place"
                                    placeholder="Start typing to Search your place"
                                    prepend-inner-icon="mdi-database-search"
                                    return-object
                                    outlined
                                    dense
                                ></v-autocomplete>

                                <v-text-field
                                    label="Address Line1"
                                    v-model="address.address_line_1"
                                    required
                                    hint
                                    persistent-hint
                                    outlined
                                    dense
                                ></v-text-field>
                                <p
                                    class="text-danger font-weight-bold"
                                    v-if="$v.address.address_line_1.$error"
                                >{{validationMessage.address_line_1}}</p>

                                <v-text-field
                                    label="Address Line 2"
                                    v-model="address.description"
                                    required
                                    outlined
                                    dense
                                ></v-text-field>

                                <!-- <v-col cols="2" md="2">
                                <v-text-field label="Post Code" v-model="address.post_code" required></v-text-field>
                                </v-col>-->
                                <v-combobox
                                    v-model="address.area"
                                    :items="areas"
                                    hide-selected
                                    label="Area"
                                    persistent-hint
                                    outlined
                                    dense
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "
                                                    <strong>{{ search }}</strong>". Press
                                                    <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>

                                <v-combobox
                                    v-model="address.city"
                                    :items="cities"
                                    hide-selected
                                    label="City"
                                    persistent-hint
                                    outlined
                                    dense
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "
                                                    <strong>{{ search }}</strong>". Press
                                                    <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                                <p
                                    class="text-danger font-weight-bold"
                                    v-if="$v.address.city.$error"
                                >{{validationMessage.city}}</p>

                                <v-combobox
                                    v-model="address.state"
                                    :items="states"
                                    hide-selected
                                    label="State"
                                    autocomplete="off"
                                    persistent-hint
                                    outlined
                                    dense
                                >
                                    <template v-slot:no-data>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    No results matching "
                                                    <strong>{{ search }}</strong>". Press
                                                    <kbd>enter</kbd> to create a new one
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-combobox>
                                <p
                                    class="text-danger font-weight-bold"
                                    v-if="$v.address.state.$error"
                                >{{validationMessage.state}}</p>

                                <!-- <v-row>
                              <v-col cols="4" md="4">
                                <v-combobox
                                  v-model="address.country_id"
                                  :items="countries"
                                  hide-selected
                                  label="Country"
                                  persistent-hint
                                >
                                  <template v-slot:no-data>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          No results matching "
                                          <strong>{{ search }}</strong>". Press
                                          <kbd>enter</kbd> to create a new one
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-combobox>
                                </v-col>-->

                                <v-checkbox v-model="showMaps" label="Display this location on Map?"></v-checkbox>

                                <v-switch label="Primary" v-model="address.is_primary"></v-switch>

                                <GmapMap
                                    style="width: 550px; height: 250px;"
                                    :zoom="8"
                                    map-type-id="roadmap"
                                    :center="center"
                                >
                                    <GmapMarker
                                        :position="position"
                                        :clickable="true"
                                        :draggable="true"
                                        v-on:dragend="changePosition($event)"
                                        @click="center=position"
                                    />
                                </GmapMap>
                            </v-col>
                        </v-row>
                        <!-- </v-row> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                    <v-btn
                        color="white--text v-btn theme--light elevation-2 v-size--large primary"
                        class="btn-save-popup"
                        text
                        :loading="isBusy"
                        @click.prevent="save"
                    >Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import Swal from "sweetalert2";
    import NotifyService from "@/common/notify.service";
    import OrganisationAddressService from "@/services/organisation/OrganisationAddressService";

    const organisationAddressService = new OrganisationAddressService();
    export default {
        name: "Locations",
        props: ["slug", "locations"],
        validations: {
            address: {
                address_line_1: {required},
                city: {required},
                area: {required},
                state: {required}
            }
        },
        data: () => ({
            isBusy: false,
            dialog: false,
            search: null,
            edit: false,
            showMaps: false,
            descriptionLimit: 60,
            entries: [],
            isLoading: false,
            model: null,
            center: {lat: 28.3949, lng: 84.124},
            cities: [],
            areas: [],
            states: [],
            countries: [],

            position: {lat: 28.253342268986668, lng: 84.93813695937502},
            markers: [
                {
                    position: {lat: 27.6711212, lng: 85.3446311}
                }
            ],
            place: null,
            validationMessage: {
                address_line_1: "Address Line 1 is required",
                city: "City is required",
                area: "Area is required",
                state: "State is required"
            },
            address: {
                organisation_id: "",
                address_line_1: "",
                address_line_2: "",
                // post_code: "",
                city: "",
                area: "",
                state: "",
                province: "",
                // country_id: "",
                geolocation_id: "",
                geo_lat: "",
                geo_long: "",
                is_primary: ""
            }
        }),
        components: {
            KTPortlet
        },
        mounted() {
            this.addresses = this.locations;
            this.getAllAddress();
            // this.$root
            //   .$confirm("Delete", "Are you sure?", { color: "" })
            //   .then(confirm => {});
        },
        methods: {
            closeDialog() {
                this.$emit("initOrganisation");
                this.dialog = false;
                this.edit = false;
                this.reset();
            },
            openDialog() {
                this.dialog = true;
                this.edit = false;
            },
            placechange() {
                // this.address.address_line_1 = this.model.address_line_1;
                // this.address.address_line_2 = this.model.address_line_2;
                // this.address.post_code = this.model.post_code;
                this.address.city = this.model.city;
                this.address.area = this.model.area;
                this.address.state = this.model.state;
                this.address.province = this.model.province;
                // this.address.country_id = this.model.country_id;
                this.address.geolocation_id = this.model.geolocation_id;
                this.address.geo_lat = parseFloat(this.model.geo_lat);
                this.address.geo_long = parseFloat(this.model.geo_long);
                this.position.lat = parseFloat(this.model.geo_lat);
                this.position.lng = parseFloat(this.model.geo_long);
                this.center = this.position;
                this.address.geolocation_id = this.model.id;
            },
            setDescription(description) {
                this.description = description;
            },
            setPlace(place) {
                this.place = place;
            },
            reset() {
                this.model = null;
                this.address = {
                    organisation_id: "",
                    address_line_1: "",
                    address_line_2: "",
                    // post_code: "",
                    city: "",
                    area: "",
                    state: "",
                    province: "",
                    // country_id: "",
                    geolocation_id: "",
                    geo_lat: "",
                    geo_long: "",
                    type: ""
                }
            },
            changePosition(e) {
                this.position.lat = e.latLng.lat();
                this.position.lng = e.latLng.lng();
                this.address.geo_lat = e.latLng.lat();
                this.address.geo_long = e.latLng.lng();
                this.center = this.position;
            },
            usePlace(place) {
                if (this.place) {
                    this.position.lat = this.place.geometry.location.lat();
                    this.position.lng = this.place.geometry.location.lng();
                    this.address.geo_lat = this.place.geometry.location.lat();
                    this.address.geo_long = this.place.geometry.location.lng();
                    this.center = this.position;
                }
                this.place = null;
            },
            editLocation: function (item) {
                this.dialog = true;
                this.edit = true;
                this.address = item;
                this.position.lat = parseFloat(this.address.geo_lat);
                this.position.lng = parseFloat(this.address.geo_long);
            },
            getAllAddress() {
                organisationAddressService.getAllAddresses().then(res => {
                    this.areas = res.data.areas;
                    this.cities = res.data.cities;
                    this.states = res.data.states;
                });
            },
            save() {
                this.$v.$touch();
                if (this.$v.address.$error) {
                } else {
                    this.isBusy = true;
                    if (!this.edit) {
                        this.saveLocation();
                    } else {
                        this.updateLocation();
                    }
                }
            },
            saveLocation() {
                organisationAddressService
                    .create(this.slug, this.address)
                    .then(res => {
                        this.$emit("initOrganisation");
                      this.closeDialog();
                        this.isBusy = false;
                        this.$snotify.success("New Address added successfully");
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            cancel() {
                this.$emit("initOrganisation");
                this.dialog = false;
            },
            updateLocation() {
                organisationAddressService
                    .update(this.slug, this.address.id, this.address)
                    .then(res => {
                        this.edit = false;
                        (this.address = {
                            organisation_id: "",
                            address_line_1: "",
                            address_line_2: "",
                            // post_code: "",
                            city: "",
                            area: "",
                            state: "",
                            province: "",
                            // country_id: "",
                            geolocation_id: "",
                            geo_lat: "",
                            geo_long: "",
                            is_primary: ""
                        }),
                            this.$emit("initOrganisation");
                        this.dialog = false;
                        this.isBusy = false;
                        this.$snotify.success("Address updated");
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            }
        },
        computed: {
            fields() {
                if (!this.model) return [];

                return Object.keys(this.model).map(key => {
                    return {
                        key,
                        value: this.model[key] || "n/a"
                    };
                });
            },
            items() {
                return this.entries.map((entry, index) => {
                    const Description = entry.area + ", " + entry.city + ", " + entry.state;
                    return Object.assign({}, entry, {Description});
                });
            }
        },

        watch: {
            search(val) {
                // Items have already been loaded
                if (this.items.length > 0) return;

                // Items have already been requested
                if (this.isLoading) return;

                this.isLoading = true;

                // Lazily load input items
                // fetch("https://api.publicapis.org/entries")
                organisationAddressService
                    .getAddress(val)
                    .then(res => {
                        const {count, result} = res.data;
                        this.count = count;
                        this.entries = result;
                    })
                    .catch(err => {
                    })
                    .finally(() => (this.isLoading = false));
            }
        }
    };
</script>
<style>
    .maps-autocomplete {
        width: 100%;
        border-bottom: 1px solid #3899ec;
    }

    .maps-autocomplete:hover,
    .maps-autocomplete:focus {
        outline: none;
    }
</style>


