<template>
  <div>
    <KTPortlet v-bind:title="'Social Media'">
      <template v-slot:toolbar>
        <v-btn
          @click="showDialog(attribute)"
          class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
          style="background:transparent;"
        >
          <i class="flaticon-edit"></i>
        </v-btn>
      </template>
      <template v-slot:body>
        <div class="kt-widget__action">
          <a
            target="_blank"
            v-if="attribute['facebook']"
            v-bind:href="attribute['facebook']"
            class="btn btn-icon btn-circle btn-label-facebook"
          >
            <i class="socicon-facebook"></i>
          </a>
          <a
            target="_blank"
            v-if="attribute['youtube']"
            v-bind:href="attribute.youtube"
            class="btn btn-icon btn-circle btn-label-youtube"
          >
            <i class="socicon-youtube"></i>
          </a>
          <a
            target="_blank"
            v-if="attribute['instagram']"
            v-bind:href="attribute.instagram"
            class="btn btn-icon btn-circle btn-label-instagram"
          >
            <i class="socicon-instagram"></i>
          </a>
          <a
            target="_blank"
            v-if="attribute['twitter']"
            v-bind:href="attribute.twitter"
            class="btn btn-icon btn-circle btn-label-twitter"
          >
            <i class="socicon-twitter"></i>
          </a>
          <a
            target="_blank"
            v-if="attribute['linkedin']"
            v-bind:href="attribute.linkedin "
            class="btn btn-icon btn-circle btn-label-linkedin"
          >
            <i class="socicon-linkedin"></i>
          </a>
          <a
            target="_blank"
            v-if="attribute['pinterest']"
            v-bind:href="attribute.pinterest "
            class="btn btn-icon btn-circle btn-label-instagram"
          >
            <i class="socicon-pinterest"></i>
          </a>
          <a
            target="_blank"
            v-if="attribute['vimeo']"
            v-bind:href="attribute.vimeo "
            class="btn btn-icon btn-circle btn-label-instagram"
          >
            <i class="socicon-vimeo"></i>
          </a>
          <a href="#" @click="showDialog(attribute)" v-if="!checkSocial">Let's get connected by adding some social media profile.</a>
        </div>
      </template>
    </KTPortlet>

    <v-dialog v-model="adddialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Social Media</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="form-block">
              <v-row>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="attribute_object.facebook"
                    label="Facebook"
                    prepend-inner-icon="socicon-facebook"
                  ></v-text-field>
                  <p
                    class="text-danger font-weight-bold"
                    v-if="$v.attribute_object.facebook.$error"
                  >Not a valid url</p>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="attribute_object.youtube"
                    label="Youtube"
                    prepend-inner-icon="socicon-youtube"
                  ></v-text-field>
                  <p
                    class="text-danger font-weight-bold"
                    v-if="$v.attribute_object.youtube.$error"
                  >Not a valid url</p>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="attribute_object.instagram"
                    label="Instagram"
                    prepend-inner-icon="socicon-instagram"
                  ></v-text-field>
                  <p
                    class="text-danger font-weight-bold"
                    v-if="$v.attribute_object.instagram.$error"
                  >Not a valid url</p>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="attribute_object.twitter"
                    label="Twitter"
                    prepend-inner-icon="socicon-twitter"
                  ></v-text-field>
                  <p
                    class="text-danger font-weight-bold"
                    v-if="$v.attribute_object.twitter.$error"
                  >Not a valid url</p>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="attribute_object.pinterest"
                    label="Pinterest"
                    prepend-inner-icon="socicon-pinterest"
                  ></v-text-field>
                  <p
                    class="text-danger font-weight-bold"
                    v-if="$v.attribute_object.pinterest.$error"
                  >Not a valid url</p>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="attribute_object.linkedin"
                    label="Linkedin"
                    prepend-inner-icon="socicon-linkedin"
                  ></v-text-field>
                  <p
                    class="text-danger font-weight-bold"
                    v-if="$v.attribute_object.linkedin.$error"
                  >Not a valid url</p>
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    v-model="attribute_object.vimeo"
                    label="Vimeo"
                    prepend-inner-icon="socicon-vimeo"
                  ></v-text-field>
                  <p
                    class="text-danger font-weight-bold"
                    v-if="$v.attribute_object.vimeo.$error"
                  >Not a valid url</p>
                </v-col>
              </v-row>
            </div>
            <!-- <pre>{{attribute_object}}</pre> -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
          <v-btn color="white--text v-btn theme--light elevation-2 v-size--large primary" class="btn-save-popup" :loading="isBusy" text @click.prevent="updateSocial">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import NotifyService from "@/common/notify.service";
import OrganisationAttributeService from "@/services/organisation/OrganisationAttributeService";
import { url } from "vuelidate/lib/validators";
const attributeService = new OrganisationAttributeService();
export default {
  name: "Social",
  props: ["slug", "attribute"],
  validations: {
    attribute_object: {
      facebook: { url },
      youtube: { url },
      instagram: { url },
      twitter: { url },
      linkedin: { url },
      pinterest: { url },
      vimeo: { url }
    }
  },
  data: () => ({
    isBusy:false,
    tags: [{ title: "" }],
    adddialog: false,
    attribute_object: {
      facebook: null,
      youtube: null,
      instagram: null,
      twitter: null,
      linkedin: null,
      pinterest: null,
      vimeo: null
    }
  }),
  components: {
    KTPortlet
  },
  created() {},
  computed: {
    mapAttribute() {
      return this.attribute;
    },
    checkSocial() {
      if (this.attribute.facebook) return true;
      if (this.attribute.twitter) return true;
      if (this.attribute.youtube) return true;
      if (this.attribute.instagram) return true;
      if (this.attribute.pinterest) return true;
      if (this.attribute.linkedin) return true;
      if (this.attribute.vimeo) return true;
      else return false;
    }
  },
  //   mounted() {this.updateAttribute = this.attribute;},
  methods: {
    closeDialog(item) {
      this.adddialog = false;
      this.$emit("initOrganisation");
    },

    showDialog(item) {
      this.adddialog = true;
      this.attribute_object = item;
    },
    isObject(arg) {
      return arg !== null && typeof arg === "object";
    },
    updateSocial() {
      this.$v.$touch();
      this.isBusy = true;
      if (!this.$v.attribute_object.$error) {
        attributeService
          .update(this.slug, this.attribute.id, this.attribute_object)
          .then(res => {
            this.$emit("initOrganisation");
            this.adddialog = false;
            this.isBusy = false;
            this.$snotify.success("Social media updated");
          }).catch(err => {
        }).finally(() => (this.isBusy = false));
      }
    },
  }
};
</script>
