<template>
    <div>
        <KTPortlet v-bind:title="'External Links'" v-if="links ? links.length>0 : 0">
            <template v-slot:toolbar>
                <v-btn
                    @click="showDialog"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                    style="background:transparent;"
                >
                    <i class="flaticon-edit"></i>
                </v-btn>
            </template>
            <template v-slot:body>
                <div class="kt-notification kt-notification--fit">
                    <a href="#" class="kt-notification__item" v-for="(link,i) in links " :key="link.id">
                        <div class="kt-notification__item-icon">
                            <i class="fa fa-external-link-alt kt-font-success"></i>
                        </div>
                        <div class="kt-notification__item-details">
                            <div class="kt-notification__item-title">
                                <a :href="link.link_url" target="_blank" class="kt-link">{{ link.title }}</a>
                                <!-- <i v-if="link.is_active" class="flaticon2-check-mark kt-font-success"></i> -->
                                <span class="pull-right">
                  <a href="#" @click="editLink(link)" class="ps-2">
                    <img src="@/assets/media/icons/edit-tools.png" class="edit_icon"/>
                  </a>
                  <a href="#" @click="deleteLinks(link.id)" class="ps-1">
                    <img src="@/assets/media/icons/send-to-trash.png" class="delete_icon"/>
                  </a>
                </span>
                            </div>
                        </div>
                    </a>
                </div>
            </template>
        </KTPortlet>

        <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower" v-else>
            <div class="kt-portlet__body">
                <div class="kt-iconbox__body">
                    <div class="kt-iconbox__icon">
                        <i class="fa fa-link kt-font-success kt-font-big"></i>
                    </div>
                    <div class="kt-iconbox__desc">
                        <h3 class="kt-iconbox__title">
                            <a class="kt-link" href="#">External Links</a>
                        </h3>
                        <div class="kt-iconbox__content">
                            <a href="#" @click="showDialog">Add important external links</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="adddialog" persistent max-width="900px">
            <v-card>
                <v-card-title>
                    <span class="headline">Manage External Links</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="form-block" v-for="(link, index) in external_link" :key="index">
                            <v-row>
                                <v-col cols="2" md="2">
                                    <v-text-field label="Title" v-model="link.title" required outlined
                                                  densed></v-text-field>
                                    <p
                                        class="text-danger font-weight-bold"
                                        v-if="$v.external_link.$each[index].title.$error"
                                    >Title is required</p>
                                </v-col>
                                <v-col cols="4" md="4">
                                    <v-text-field label="Url" v-model="link.link_url" required outlined
                                                  densed></v-text-field>
                                    <p
                                        class="text-danger font-weight-bold"
                                        v-if="$v.external_link.$each[index].link_url.$error"
                                    >Link Url is required and Valid</p>
                                </v-col>
                                <v-col cols="2" md="2">
                                    <v-text-field label="Position" v-model="link.sort_position" required outlined
                                                  densed></v-text-field>
                                    <p
                                        class="text-danger font-weight-bold"
                                        v-if="$v.external_link.$each[index].sort_position.$error"
                                    >Postition is required</p>
                                </v-col>
                                <v-col cols="2" md="2">
                                    <v-switch label="Active" v-model="link.is_active"></v-switch>
                                </v-col>
                                <v-col cols="2" md="2">
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        x-small
                                        color="red"
                                        v-if="index!=0"
                                        @click="removeLinks(index)"
                                    >
                                        <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        dark
                                        x-small
                                        v-if="index===external_link.length-1 && !edit"
                                        color="green"
                                        @click="addLinks"
                                    >
                                        <v-icon dark>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- <pre>
                          {{ external_link }}
                        </pre>-->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="white--text v-btn theme--light elevation-2 v-size--large primary"
                           class="btn-save-popup" :loading="isBusy" text @click.prevent="saveOrUpdate">Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required, url} from "vuelidate/lib/validators";
    import OgranisationExternalLinksService from "@/services/organisation/OgranisationExternalLinksService";

    const ogranisationLinks = new OgranisationExternalLinksService();
    export default {
        name: "ExternalLinks",
        props: ["slug", "links"],
        validations: {
            external_link: {
                required,
                $each: {
                    title: {required},
                    link_url: {required, url},
                    sort_position: {required}
                }
            }
        },

        data: () => ({
            isBusy: false,
            adddialog: false,
            edit: false,
            external_link: [
                {
                    title: "",
                    link_url: "",
                    is_bold: "",
                    image: "",
                    sort_position: 1,
                    type: "",
                    is_active: 1
                }
            ]
        }),
        components: {
            KTPortlet
        },
        methods: {
            showDialog() {
                this.adddialog = true;
            },
            close() {
                this.adddialog = false;
                this.$emit("initOrganisation");
            },
            addLinks() {
                this.external_link.push({
                    title: "",
                    link_url: "",
                    is_bold: "",
                    image: "",
                    sort_position: 1,
                    type: "",
                    is_active: 1
                });
            },
            removeLinks(index) {
                this.external_link.splice(index, 1);
            },
            editLink(item) {
                if (item.id != null && item.id != undefined && item != "") {
                    this.adddialog = true;
                    this.edit = true;
                    this.external_link = [];
                    this.external_link.push(item);
                }
            },
            saveOrUpdate() {
                this.$v.$touch();
                if (this.$v.external_link.$error) {
                    this.$v.external_link.$reset();
                } else {
                    this.isBusy = true;
                    if (!this.edit) {
                        this.save();
                    } else {
                        this.update();
                    }
                }
            },
            save() {
                ogranisationLinks
                    .saveMultiple(this.slug, this.external_link)
                    .then(res => {
                        this.resetLinks();
                        this.isBusy = false;
                        this.$emit("initOrganisation");
                        this.adddialog = false;
                        this.$snotify.success("Link added");
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            update() {
                ogranisationLinks
                    .update(this.slug, this.external_link[0].id, this.external_link[0])
                    .then(res => {
                        this.resetLinks();
                        this.isBusy = false;
                        this.$snotify.success("Link updated");
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            resetLinks() {
                this.$v.$reset()
                this.edit = false;
                this.$emit("initOrganisation");
                this.adddialog = false;
                this.external_link = [];
                this.external_link.push({
                    title: "",
                    link_url: "",
                    is_bold: "",
                    image: "",
                    sort_position: 1,
                    type: "",
                    is_active: 1
                });
            },
            // Delete Faq
            deleteLinks(id) {
                this.$confirm(
                    {
                        message: `Confirm delete link ? `,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                ogranisationLinks
                                    .delete(this.slug, id)
                                    .then(res => {
                                        this.$emit("initOrganisation");
                                        this.$snotify.success("Link deleted");
                                    })
                                    .catch(err => {

                                    });
                            }
                        }
                    }
                )

            }
        }
    };
</script>
