import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class organisationMediaService {
    paginate(slug,index = null) {
        let url = API_URL + 'user/organisation/' + slug + '/gallery';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }
    get(slug) {
        let url = API_URL + 'user/organisation/' + slug + '/gallery';
        return apiService.get(url);
    }
    create(slug,data) {

        let url = API_URL + 'user/organisation/' + slug + '/gallery';
        return apiService.post(url, data);
    }

    update(slug,id, data) {
        let url = API_URL + 'user/organisation/' + slug + '/gallery/'+id;
        return apiService.put(url, data);
    }

    delete(slug,id) {
        let url = API_URL + 'user/organisation/' + slug + '/gallery/'+id;
        return apiService.delete(url);
    }
    show(id) {
        let url = API_URL + 'user/organisation/' + slug + '/gallery/'+id;
        return apiService.get(url);
    }

}
