import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class OrganisationReviewService {
    paginate(index = null) {
        let url = API_URL + 'user/directory-review';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }
    paginateByOrg(slug, index = null) {
        let url = API_URL + 'user/organisation/' + slug + '/review';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }


}
