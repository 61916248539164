<template>
    <div>
        <KTPortlet v-bind:title="'Business Hours'" v-if="business_hour">
            <template v-slot:toolbar>
                <v-btn
                    @click="showOhDialog"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                    style="background:transparent;"
                >
                    <i class="flaticon-add-circular-button"></i>
                </v-btn>
            </template>
            <template v-slot:body>
                <table class="opening_hours">
                    <tbody>
                    <tr class>
                        <th>Sunday</th>
                        <td
                            class="text-right"
                            v-bind:class="[business_hour.formated_hours.sunday == 'Closed' ? 'text-danger' : business_hour.formated_hours.sunday == 'Always Open' ? 'text-success' : '']"
                        >{{ business_hour.formated_hours.sunday }}
                        </td>
                    </tr>
                    <tr class="highlight-time">
                        <th>Monday</th>
                        <td
                            class="text-right"
                            v-bind:class="[business_hour.formated_hours.monday == 'Closed' ? 'text-danger' : business_hour.formated_hours.monday == 'Always Open' ? 'text-success' : '']"
                        >{{ business_hour.formated_hours.monday }}
                        </td>
                    </tr>
                    <tr class>
                        <th>Tuesday</th>
                        <td
                            class="text-right"
                            v-bind:class="[business_hour.formated_hours.tuesday == 'Closed' ? 'text-danger' : business_hour.formated_hours.tuesday == 'Always Open' ? 'text-success' : '']"
                        >{{ business_hour.formated_hours.tuesday }}
                        </td>
                    </tr>
                    <tr class>
                        <th>Wednesday</th>
                        <td
                            class="text-right"
                            v-bind:class="[business_hour.formated_hours.wednesday == 'Closed' ? 'text-danger' : business_hour.formated_hours.wednesday == 'Always Open' ? 'text-success' : '']"
                        >{{ business_hour.formated_hours.wednesday }}
                        </td>
                    </tr>
                    <tr class>
                        <th>Thursday</th>
                        <td
                            class="text-right"
                            v-bind:class="[business_hour.formated_hours.thursday == 'Closed' ? 'text-danger' : business_hour.formated_hours.thursday == 'Always Open' ? 'text-success' : '']"
                        >{{ business_hour.formated_hours.thursday }}
                        </td>
                    </tr>
                    <tr class>
                        <th>Friday</th>
                        <td
                            class="text-right"
                            v-bind:class="[business_hour.formated_hours.friday == 'Closed' ? 'text-danger' : business_hour.formated_hours.friday == 'Always Open' ? 'text-success' : '']"
                        >{{ business_hour.formated_hours.friday }}
                        </td>
                    </tr>
                    <tr class>
                        <th>Saturday</th>
                        <td
                            class="text-right"
                            v-bind:class="[business_hour.formated_hours.saturday == 'Closed' ? 'text-danger' : business_hour.formated_hours.saturday == 'Always Open' ? 'text-success' : '']"
                        >
                            {{ business_hour.formated_hours.saturday }}
                            <span class="text-danger"></span>
                        </td>
                    </tr>
                    <tr>
                        <div class="kt-separator kt-separator--space-lg kt-separator--border-dashed"></div>
                    </tr>
                    <tr class>
                        <th>Special Opening Hours</th>
                        <td class="text-right">
                            <v-dialog v-model="edialog" persistent max-width="900px">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        v-on="on"
                                        class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                                        style="background:transparent;"
                                    >
                                        <i class="flaticon-edit"></i>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">Special Opening Hours</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-form v-model="valid" ref="businessException" lazy-validation>
                                            <v-container>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <v-row align="center" justify="center">
                                                    <v-col cols="12" sm="10" md="12">
                                                        <v-text-field
                                                            v-model="newexception.title"
                                                            persistent-hint
                                                            :error-messages="nameErrors"
                                                            @blur="$v.newexception.title.$touch()"
                                                            hint="Eg. Labour Day"
                                                            label="Day Title "
                                                            outlined
                                                            densed
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="3" md="12">
                                                        <v-menu
                                                            ref="exceptiondate"
                                                            v-model="menu"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="290px"
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-text-field
                                                                    v-model="newexception.dates"
                                                                    range
                                                                    label="Select Start & End Date for this opening hours"
                                                                    prepend-inner-icon="event"
                                                                    readonly
                                                                    v-on="on"
                                                                    outlined
                                                                    densed
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                range
                                                                ref="picker"
                                                                v-model="newexception.dates"
                                                                @change="saveDate"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12">Hours of operation</v-col>
                                                    <v-col cols="12" sm="10" md="1">
                                                        <v-checkbox
                                                            @change="exceptionStatus"
                                                            v-model="newexception.status"
                                                            color="success"
                                                            hide-details
                                                        ></v-checkbox>
                                                    </v-col>

                                                    <v-col cols="10" sm="10" md="10" v-if="!newexception.status">
                                                        <span
                                                            class="kt-badge kt-badge--danger kt-badge--inline">Closed</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="4"
                                                        sm="4"
                                                        md="4"
                                                        v-if="newexception.status && newexception.always"
                                                    >
                                                        <span class="kt-badge kt-badge--success kt-badge--inline">24 Hours</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="6"
                                                        sm="6"
                                                        md="3"
                                                        v-if="newexception.status  && !newexception.always"
                                                    >
                                                        <Time v-model="newexception.exception_start_time"></Time>
                                                    </v-col>

                                                    <v-col
                                                        cols="6"
                                                        sm="4"
                                                        md="3"
                                                        v-if="newexception.status && !newexception.always"
                                                    >
                                                        <Time input-width="150px"
                                                              v-model="newexception.exception_end_time"></Time>
                                                    </v-col>
                                                    <v-col cols="2" sm="2" md="4" v-if="newexception.status ">
                                                        <v-checkbox
                                                            @change="exceptionAlways"
                                                            v-model="newexception.always"
                                                            label="Always Open"
                                                            color="success"
                                                            hide-details
                                                        ></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="edialog = false">Close</v-btn>
                                        <v-btn
                                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                            class="btn-save-popup"
                                            :loading="isExceptionBusy"
                                            text
                                            @click="addException"
                                        >Save
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </td>
                    </tr>
                    <tr v-if="exception ? exception.length>0 : false">
                        <!--              <th>Title</th>-->
                        <!--              <td class="text-right">From - To</td>-->
                    </tr>
                    <tr v-for="item in exception" :key="item.id">
                        <th>
                            {{ item.exception_title }}
                            <span @click="deleteException(item.id)" class="ps-1">
                  <img src="@/assets/media/icons/send-to-trash.png" class="delete_icon"/>
                </span>
                        </th>
                        <td
                            class="text-right"
                            v-bind:class="[item.formated_exception.time == 'Closed' ? 'text-danger' : item.formated_exception.time == 'Always Open' ? 'text-success' : '']"
                        >
                            {{ item.formated_exception.start }} - {{ item.formated_exception.end }}
                            <br/>
                            {{ item.formated_exception.time }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </KTPortlet>

        <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower" v-else>
            <div class="kt-portlet__body">
                <div class="kt-iconbox__body">
                    <div class="kt-iconbox__icon">
                        <i class="flaticon-time kt-font-success kt-font-big"></i>
                    </div>
                    <div class="kt-iconbox__desc">
                        <h3 class="kt-iconbox__title">
                            <a class="kt-link" href="#">Business Hours</a>
                        </h3>
                        <div class="kt-iconbox__content">
                            <a href="#" @click="showOhDialog">Manage operating hours and time</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="bdialog" persistent max-width="650px">
            <v-card>
                <v-card-title>
                    <span class="headline">Opening Hours</span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-radio-group v-model="option" @change="changeHour" column>
                                <v-radio label="Opens 9:00 AM to 5:00 PM" value="standard"></v-radio>
                                <!-- <v-radio label="Open 24 Hours" value="always"></v-radio> -->
                                <!-- <v-radio label="My Business Closed All Time [ Closed 24hr ]" value="closed"></v-radio> -->
                            </v-radio-group>
                        </v-col>

                        <v-col cols="12" sm="10" md="2">
                            <v-checkbox
                                @change="sundayStatus"
                                v-model="sunday.status"
                                color="success"
                                label="Sunday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" sm="4" md="3" v-if="sunday.status && !sunday.always">
                            <Time input-width="150px" v-model="opening_hours.sunday_open"></Time>
                        </v-col>

                        <v-col cols="6" sm="4" md="3" v-if="sunday.status && !sunday.always">
                            <Time input-width="150px" v-model="opening_hours.sunday_close"></Time>
                        </v-col>
                        <v-col cols="8" sm="8" md="6" v-else-if="sunday.status &&sunday.always">
                            <span class="kt-badge kt-badge--success kt-badge--inline">Opened All Day</span>
                        </v-col>
                        <v-col cols="8" sm="8" md="10" v-else>
                            <span class="kt-badge kt-badge--danger kt-badge--inline">Closed</span>
                        </v-col>
                        <v-col cols="12" sm="10" md="4" v-if="sunday.status">
                            <v-checkbox
                                v-model="sunday.always"
                                color="primary"
                                label="Always Open"
                                @change="sundayalways"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="10" md="2">
                            <v-checkbox
                                @change="mondayStatus"
                                v-model="monday.status"
                                color="success"
                                label="Monday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" sM="4" md="3" v-if="monday.status && !monday.always">
                            <Time input-width="150px" v-model="opening_hours.monday_open"></Time>
                        </v-col>

                        <v-col cols="6" sm="4" md="3" v-if="monday.status && !monday.always">
                            <Time input-width="150px" v-model="opening_hours.monday_close"></Time>
                        </v-col>
                        <v-col cols="8" sm="8" md="6" v-else-if="monday.status && monday.always">
                            <span class="kt-badge kt-badge--success kt-badge--inline">Opened All Day</span>
                        </v-col>
                        <v-col cols="8" sm="8" md="10" v-else>
                            <span class="kt-badge kt-badge--danger kt-badge--inline">Closed</span>
                        </v-col>
                        <v-col cols="12" sm="10" md="4" v-if="monday.status">
                            <v-checkbox
                                v-model="monday.always"
                                color="primary"
                                label="Always Open"
                                @change="mondayalways"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="10" md="2">
                            <v-checkbox
                                v-model="tuesday.status"
                                @change="tuesdayStatus"
                                color="success"
                                label="Tuesday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" sm="4" md="3" v-if="tuesday.status && !tuesday.always">
                            <Time input-width="150px" v-model="opening_hours.tuesday_open"></Time>
                        </v-col>

                        <v-col cols="6" sm="4" md="3" v-if="tuesday.status && !tuesday.always">
                            <Time input-width="150px" v-model="opening_hours.tuesday_close"></Time>
                        </v-col>
                        <v-col cols="8" sm="8" md="6" v-else-if="tuesday.status && tuesday.always">
                            <span class="kt-badge kt-badge--success kt-badge--inline">Opened All Day</span>
                        </v-col>
                        <v-col cols="8" sm="8" md="10" v-else>
                            <span class="kt-badge kt-badge--danger kt-badge--inline">Closed</span>
                        </v-col>
                        <v-col cols="12" sm="10" md="4" v-if="tuesday.status">
                            <v-checkbox
                                v-model="tuesday.always"
                                color="primary"
                                label="Always Open"
                                @change="tuesdayalways"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="10" md="2">
                            <v-checkbox
                                v-model="wednesday.status"
                                @change="wednesdayStatus"
                                color="success"
                                label="Wednesday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" sm="4" md="3" v-if="wednesday.status && !wednesday.always">
                            <Time input-width="150px" v-model="opening_hours.wednesday_open"></Time>
                        </v-col>

                        <v-col cols="6" sm="4" md="3" v-if="wednesday.status && !wednesday.always">
                            <Time input-width="150px" v-model="opening_hours.wednesday_close"></Time>
                        </v-col>
                        <v-col cols="8" sm="8" md="6" v-else-if="wednesday.status && wednesday.always">
                            <span class="kt-badge kt-badge--success kt-badge--inline">Opened All Day</span>
                        </v-col>
                        <v-col cols="8" sm="8" md="10" v-else>
                            <span class="kt-badge kt-badge--danger kt-badge--inline">Closed</span>
                        </v-col>
                        <v-col cols="12" sm="10" md="4" v-if="wednesday.status">
                            <v-checkbox
                                v-model="wednesday.always"
                                color="primary"
                                label="Always Open"
                                @change="wednesdayalways"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="10" md="2">
                            <v-checkbox
                                v-model="thursday.status"
                                @change="thursdayStatus"
                                color="success"
                                label="Thursday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" sm="4" md="3" v-if="thursday.status && !thursday.always">
                            <Time input-width="150px" v-model="opening_hours.thursday_open"></Time>
                        </v-col>

                        <v-col cols="6" sm="4" md="3" v-if="thursday.status && !thursday.always">
                            <Time input-width="150px" v-model="opening_hours.thursday_close"></Time>
                        </v-col>
                        <v-col cols="8" sm="8" md="6" v-else-if="thursday.status && thursday.always">
                            <span class="kt-badge kt-badge--success kt-badge--inline">Opened All Day</span>
                        </v-col>
                        <v-col cols="8" sm="8" md="10" v-else>
                            <span class="kt-badge kt-badge--danger kt-badge--inline">Closed</span>
                        </v-col>
                        <v-col cols="12" sm="10" md="4" v-if="thursday.status">
                            <v-checkbox
                                v-model="thursday.always"
                                color="primary"
                                label="Always Open"
                                @change="thursdayalways"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="10" md="2">
                            <v-checkbox
                                @change="fridayStatus"
                                v-model="friday.status"
                                color="success"
                                label="Friday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" sm="4" md="3" v-if="friday.status && !friday.always">
                            <Time input-width="150px" v-model="opening_hours.friday_open"></Time>
                        </v-col>

                        <v-col cols="6" sm="4" md="3" v-if="friday.status && !friday.always">
                            <Time input-width="150px" v-model="opening_hours.friday_close"></Time>
                        </v-col>
                        <v-col cols="8" sm="8" md="6" v-else-if="friday.status && friday.always">
                            <span class="kt-badge kt-badge--success kt-badge--inline">Opened All Day</span>
                        </v-col>
                        <v-col cols="8" sm="8" md="10" v-else>
                            <span class="kt-badge kt-badge--danger kt-badge--inline">Closed</span>
                        </v-col>
                        <v-col cols="12" sm="10" md="4" v-if="friday.status">
                            <v-checkbox
                                v-model="friday.always"
                                color="primary"
                                label="Always Open"
                                @change="fridayalways"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="10" md="2">
                            <v-checkbox
                                v-model="saturday.status"
                                @change="saturdayStatus"
                                color="success"
                                label="Saturday"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" sm="4" md="3" v-if="saturday.status && !saturday.always">
                            <Time input-width="150px" v-model="opening_hours.saturday_open"></Time>
                        </v-col>

                        <v-col cols="6" sm="4" md="3" v-if="saturday.status && !saturday.always">
                            <Time input-width="150px" v-model="opening_hours.saturday_close"></Time>
                        </v-col>
                        <v-col cols="8" sm="8" md="6" v-else-if="saturday.status && saturday.always">
                            <span class="kt-badge kt-badge--success kt-badge--inline">Opened All Day</span>
                        </v-col>
                        <v-col cols="8" sm="8" md="10" v-else>
                            <span class="kt-badge kt-badge--danger kt-badge--inline">Closed</span>
                        </v-col>
                        <v-col cols="12" sm="10" md="4" v-if="saturday.status">
                            <v-checkbox
                                v-model="saturday.always"
                                color="primary"
                                label="Always Open"
                                @change="saturdayalways"
                                hide-details
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="bdialog = false">Close</v-btn>
                    <v-btn
                        color="white--text v-btn theme--light elevation-2 v-size--large primary"
                        class="btn-save-popup"
                        :loading="isBusy"
                        text
                        @click="save"
                    >Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import "vue-datetime/dist/vue-datetime.css";
    import NotifyService from "@/common/notify.service";
    import OrganisationBusinessHourService from "@/services/organisation/OrganisationBusinessHourService";

    const organisationBusinessHourService = new OrganisationBusinessHourService();
    import {validationMixin} from "vuelidate";
    import {email, required, minLength, sameAs} from "vuelidate/lib/validators";
    import Time from "vue2-timepicker/src/vue-timepicker.vue";

    export default {
        name: "businessHour",
        props: ["slug", "business_hour", "exception"],
        validations: {
            newexception: {
                title: {required}
            }
        },

        components: {
            KTPortlet,
            Time
        },
        data: () => ({
            isBusy: false,
            isExceptionBusy: false,
            bdialog: false,
            edialog: false,
            valid: false,
            option: "",
            menu: false,
            opening_hours: {
                id: null,
                sunday_open: "10:00",
                sunday_close: "17:00",
                monday_open: "10:00",
                monday_close: "17:00",
                tuesday_open: "10:00",
                tuesday_close: "17:00",
                wednesday_open: "10:00",
                wednesday_close: "17:00",
                thursday_open: "10:00",
                thursday_close: "17:00",
                friday_open: "10:00",
                friday_close: "17:00",
                saturday_open: "10:00",
                saturday_close: "17:00",
                is_exception: 0,
                exception_title: "",
                exception_start_date: "",
                exception_finish_date: "",
                is_exception_recurring: 0,
                is_active: 1
            },
            sunday: {
                status: true,
                always: false,
                topen: false,
                tclose: false
            },
            monday: {
                status: true,
                always: false,
                topen: false,
                tclose: false
            },
            tuesday: {
                status: true,
                always: false,
                topen: false,
                tclose: false
            },
            wednesday: {
                status: true,
                always: false,
                topen: false,
                tclose: false
            },
            thursday: {
                status: true,
                always: false,
                topen: false,
                tclose: false
            },
            friday: {
                status: true,
                always: false,
                topen: false,
                tclose: false
            },
            saturday: {
                status: false,
                always: false,
                topen: false,
                tclose: false
            },
            newexceptionList: {
                is_exception: 1,
                exception_title: "Dashain Bida",
                exception_start_date: "2019-05-05",
                exception_finish_date: "2019-05-20",
                is_exception_recurring: true,
                is_active: 1
            },

            newexception: {
                id: "",
                title: "",
                exception_start_time: "09:00",
                exception_end_time: "15:00",
                dates: [],
                status: true,
                always: false,
                ref: false,
                topen: false,
                tclose: false
            }
        }),

        computed: {
            nameErrors() {
                const errors = [];
                if (!this.$v.newexception.title.$dirty) return errors;
                !this.$v.newexception.title.required && errors.push("Name is required.");
                return errors;
            }
        },
        mounted() {
        },
        methods: {
            deleteException(id) {
                this.$confirm(
                    {
                        message: `Confirm delete  item ?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                organisationBusinessHourService.delete(this.slug, id).then(res => {
                                    this.$emit("initOrganisation");
                                    this.$snotify.success("Exception Deleted Sucessfully");
                                });
                            }
                        }
                    }
                )

            },
            showOhDialog() {
                this.bdialog = true;
            },
            sundayStatus: function () {
                if (!this.sunday.status) {
                    this.opening_hours.sunday_open = "";
                    this.opening_hours.sunday_close = "";
                }
            },
            mondayStatus: function () {
                if (!this.monday.status) {
                    this.opening_hours.monday_open = "";
                    this.opening_hours.monday_close = "";
                }
            },
            tuesdayStatus: function () {
                if (!this.tuesday.status) {
                    this.opening_hours.tuesday_open = "";
                    this.opening_hours.tuesday_close = "";
                }
            },
            wednesdayStatus: function () {
                if (!this.wednesday.status) {
                    this.opening_hours.wednesday_open = "";
                    this.opening_hours.wednesday_close = "";
                }
            },
            thursdayStatus: function () {
                if (!this.thursday.status) {
                    this.opening_hours.thursday_open = "";
                    this.opening_hours.thursday_close = "";
                }
            },
            fridayStatus: function () {
                if (!this.friday.status) {
                    this.opening_hours.friday_open = "";
                    this.opening_hours.friday_close = "";
                }
            },
            saturdayStatus: function () {
                if (!this.saturday.status) {
                    this.opening_hours.saturday_open = "";
                    this.opening_hours.saturday_close = "";
                }
            },
            sundayalways: function () {
                if (this.sunday.always) {
                    this.opening_hours.sunday_open = "00:00";
                    this.opening_hours.sunday_close = "00:00";
                }
            },
            mondayalways: function () {
                if (this.monday.always) {
                    this.opening_hours.monday_open = "00:00";
                    this.opening_hours.monday_close = "00:00";
                }
            },
            tuesdayalways: function () {
                if (this.tuesday.always) {
                    this.opening_hours.tuesday_open = "00:00";
                    this.opening_hours.tuesday_close = "00:00";
                }
            },
            wednesdayalways: function () {
                if (this.wednesday.always) {
                    this.opening_hours.wednesday_open = "00:00";
                    this.opening_hours.wednesday_close = "00:00";
                }
            },
            thursdayalways: function () {
                if (this.thursday.always) {
                    this.opening_hours.thursday_open = "00:00";
                    this.opening_hours.thursday_close = "00:00";
                }
            },
            fridayalways: function () {
                if (this.friday.always) {
                    this.opening_hours.friday_open = "00:00";
                    this.opening_hours.friday_close = "00:00";
                }
            },
            saturdayalways: function () {
                if (this.saturday.always) {
                    this.opening_hours.saturday_open = "00:00";
                    this.opening_hours.saturday_close = "00:00";
                }
            },
            exceptionStatus: function () {
                if (!this.newexception.status) {
                    this.newexception.opening_time = "";
                    this.newexception.closing_time = "";
                }
            },
            exceptionAlways: function () {
                if (this.newexception.always) {
                    this.newexception.opening_time = "00:00";
                    this.newexception.closing_time = "00:00";
                }
            },

            changeHour() {
                switch (this.option) {
                    case "standard":
                        this.changeToStandard();
                        break;
                    case "always":
                        this.changeToAlwaysOn();
                        break;
                    default:
                }
            },
            changeToStandard() {
                //set standard Days Timing
                (this.opening_hours.sunday_open = "10:00"),
                    (this.opening_hours.sunday_close = "17:00"),
                    (this.opening_hours.monday_open = "10:00"),
                    (this.opening_hours.monday_close = "15:00"),
                    (this.opening_hours.tuesday_open = "10:00"),
                    (this.opening_hours.tuesday_close = "17:00"),
                    (this.opening_hours.wednesday_open = "10:00"),
                    (this.opening_hours.wednesday_close = "17:00"),
                    (this.opening_hours.thursday_open = "10:00"),
                    (this.opening_hours.thursday_close = "17:00"),
                    (this.opening_hours.friday_open = "10:00"),
                    (this.opening_hours.friday_close = "17:00"),
                    (this.opening_hours.saturday_open = ""),
                    (this.opening_hours.saturday_close = ""),
                    (this.sunday.status = true);

                //set standard Days On
                this.monday.status = true;
                this.tuesday.status = true;
                this.wednesday.status = true;
                this.thursday.status = true;
                this.friday.status = true;
                this.saturday.status = false;
                //set standard Always Off
                this.sunday.always = false;
                this.monday.always = false;
                this.tuesday.always = false;
                this.wednesday.always = false;
                this.thursday.always = false;
                this.friday.always = false;
                this.saturday.always = false;
            },

            changeToAlwaysOn() {
                //set always on
                (this.opening_hours.sunday_open = "00:00"),
                    (this.opening_hours.sunday_close = "00:00"),
                    (this.opening_hours.monday_open = "00:00"),
                    (this.opening_hours.monday_close = "00:00"),
                    (this.opening_hours.tuesday_open = "00:00"),
                    (this.opening_hours.tuesday_close = "00:00"),
                    (this.opening_hours.wednesday_open = "00:00"),
                    (this.opening_hours.wednesday_close = "00:00"),
                    (this.opening_hours.thursday_open = "00:00"),
                    (this.opening_hours.thursday_close = "00:00"),
                    (this.opening_hours.friday_open = "00:00"),
                    (this.opening_hours.friday_close = "00:00"),
                    (this.opening_hours.saturday_open = "00:00"),
                    (this.opening_hours.saturday_close = "00:00"),
                    (this.sunday.status = true);

                //set standard Days On
                this.monday.status = true;
                this.tuesday.status = true;
                this.wednesday.status = true;
                this.thursday.status = true;
                this.friday.status = true;
                this.saturday.status = true;
                //set Always Always Off
                this.sunday.always = true;
                this.monday.always = true;
                this.tuesday.always = true;
                this.wednesday.always = true;
                this.thursday.always = true;
                this.friday.always = true;
                this.saturday.always = true;
            },
            save() {
                this.isBusy = true;
                if (this.business_hour == null) {
                    organisationBusinessHourService
                        .create(this.slug, this.opening_hours)
                        .then(res => {
                            this.$emit("initOrganisation");
                            this.bdialog = false;
                            this.isBusy = false;
                            this.$snotify.success("Business hour added");
                        }).catch(err => {
                    }).finally(() => (this.isBusy = false));
                } else {
                    organisationBusinessHourService
                        .update(this.slug, this.business_hour.id, this.opening_hours)
                        .then(res => {
                            this.$emit("initOrganisation");
                            this.bdialog = false;
                            this.$snotify.success("Business hour updated");
                        }).catch(err => {
                    }).finally(() => (this.isBusy = false));
                }
            },

            saveDate(date) {
                this.$refs.exceptiondate.save(date);
            },
            saveTime(ref, time) {
                this.exceptions[i].opening_time = time;
            },

            addException() {
                this.$v.newexception.$touch();
                if (this.$v.newexception.$error) {
                } else {
                    let exception = [
                        {
                            is_exception: 1,
                            exception_title: this.newexception.title,
                            exception_start_time: this.newexception.exception_start_time,
                            exception_end_time: this.newexception.exception_end_time,
                            exception_start_date: this.newexception.dates[0],
                            exception_finish_date: this.newexception.dates[1],
                            is_exception_recurring: true,
                            is_active: 1
                        }
                    ];
                    this.isExceptionBusy = true;
                    organisationBusinessHourService
                        .createException(this.slug, exception)
                        .then(res => {
                            this.isExceptionBusy = false;
                            this.newexception = {};
                            this.$v.$reset();
                            this.$emit("initOrganisation");
                            this.edialog = false;
                            this.$snotify.success("Opening Hours updated");
                        })
                        .catch(err => {
                        });
                }
            }
        }
    };
</script>

<style>
    .vue__time-picker input.display-time {
        font-size: 1.4em !important;
    }

    .btn-transparent {
        background: transparent;
        box-shadow: none !important;
    }

    .v-input .v-label {
        font-size: 12px !important;
        line-height: 15px !important;
    }

    .v-text-field__slot input {
        font-size: 13px;
    }
</style>

