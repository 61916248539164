import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class QuestionnaireService {
    getAll(slug) {
        let url = API_URL + 'user/organisation/' + slug + '/review-questionnaire';
        return apiService.get(url);

    }

    paginate(slug, index = null) {
        let url = API_URL + 'user/organisation/' + slug + '/review-questionnaire';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/organisation/' + slug + '/review-questionnaire'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/organisation/' + slug + '/review-questionnaire/' + id
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/organisation/' + slug + '/review-questionnaire/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/organisation/' + slug + '/review-questionnaire/' + id
        return apiService.get(url);
    }


}
