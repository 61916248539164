
export function isNullablePhoneNumner(value) {
    if (value) 
        return (/^(\+\d{1,3}[- ]*)*\d{7,10}$/.test(value)) ? true : false;
    else
        return true

}
export function isPhoneNumner(value) {
    return (/^(\+\d{1,3}[- ]?)?\d{7,10}$/.test(value)) ? true : false;
}

export function isMobileNumber(value) {
    return (/^(\+\d{1,3}[- ]?)?\d{10}$/.test(value)) ? true : false;
}

export function isNullableMobileNumber(value) {
    if (value.length < 1)
        return true;
    return (/^(\+\d{1,3}[- ]?)?\d{10}$/.test(value)) ? true : false;
}