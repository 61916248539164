<template>
  <div>
    <KTPortlet v-bind:title="'Accepted Payments'" v-if="apayments ? apayments : false">
      <template v-slot:toolbar>
        <v-btn
          @click="showDialog(apayments)"
          class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
          style="background:transparent;"
        >
          <i class="flaticon-edit"></i>
        </v-btn>
      </template>
      <template v-slot:body>
        <div class="kt-section__content d-flex flex-wrap kt-section__content--solid--">
          <span v-if="apayments.cash" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/cash.png" alt="Cash" />
          </span>
          <span v-if="apayments.card" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/card.png" alt="Card" />
          </span>
          <span v-if="apayments.paypal" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/paypal.png" alt="Paypal" />
          </span>
          <span v-if="apayments.bpay" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/bpay.png" alt="BPay" />
          </span>
          <span v-if="apayments.cheques" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/cheque.png" alt="Cheque" />
          </span>
          <span v-if="apayments.mobile_pay" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/mobile.png" alt="Mobile Transfer" />
          </span>
          <span v-if="apayments.khalti" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/khalti-new.png" alt="Khalti" />
          </span>
          <span v-if="apayments.esewa" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/esewa.png" alt="Esewa" />
          </span>
          <span v-if="apayments.wiretransfer" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/wireTransfer.png" alt="Wire Transfer" />
          </span>
          <span v-if="apayments.ipay" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/ipay.png" alt="iPay" />
          </span>
          <span v-if="apayments.edhewa" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/e-dheba.png" alt="E-dheba" />
          </span>
          <span v-if="apayments.direct_debit" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/directdebit.png" alt="Direct Debit" />
          </span>
          <span v-if="apayments.eftpos" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/eftpos.png" alt="Eftpos" />
          </span>
          <span v-if="apayments.american_express" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/american_express.png" alt="American Express" />
          </span>
          <span v-if="apayments.mastercard" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/mastercard.png" alt="Master Card" />
          </span>
          <span v-if="apayments.visa" class="kt-media kt-margin-r-5 kt-margin-t-1">
            <img src="/assets/media/payment-options/visa.png" alt="Visa" />
          </span>
        </div>
        <!-- <pre>{{apayments}}</pre> -->
      </template>
    </KTPortlet>

    <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower" v-else>
      <div class="kt-portlet__body">
        <div class="kt-iconbox__body">
          <div class="kt-iconbox__icon">
            <i class="fa fa-money-check-alt kt-font-success kt-font-big"></i>
          </div>
          <div class="kt-iconbox__desc">
            <h3 class="kt-iconbox__title">
              <a class="kt-link" href="#">Accepted Payments</a>
            </h3>
            <div class="kt-iconbox__content">
              <a href="#" @click="showDialog">List payment methods accepted by your business</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="adddialog" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">Accepted Payments</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="form-block">
              <v-row>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.cash" label="Cash"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.card" label="Card"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.paypal" label="Paypal"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.bpay" label="Bpay"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.cheques" label="Cheques"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.mobile_pay" label="Mobile Pay"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.khalti" label="Khalti"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.esewa" label="Esewa"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.wiretransfer" label="Wire Transfer"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.ipay" label="iPay"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.edhewa" label="E-Dhewa"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.direct_debit" label="Direct Deposit"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.eftpos" label="EftPos"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.american_express" label="American Express"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.mastercard" label="Master Card"></v-checkbox>
                </v-col>
                <v-col cols="3" md="3">
                  <v-checkbox v-model="payment_option.visa" label="Visa"></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="white--text v-btn theme--light elevation-2 v-size--large primary" class="btn-save-popup" :loading="isBusy"  text @click.prevent="saveOrupdate">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import NotifyService from "@/common/notify.service";
import OrganisationAcceptedPaymentService from "@/services/organisation/OrganisationAcceptedPaymentService";
const accptedPayment = new OrganisationAcceptedPaymentService();
export default {
  name: "PayementOption",
  props: ["slug", "apayments"],
  data: () => ({
    isBusy : false,
    adddialog: false,
    edit: false,
    payment_option: {
      id: "",
      organisation_id: "",
      cash: false,
      card: false,
      paypal: false,
      bpay: false,
      cheques: false,
      mobile_pay: false,
      khalti: false,
      esewa: false,
      wiretransfer: false,
      ipay: false,
      edhewa: false,
      direct_debit: false,
      eftpos: false,
      american_express: false,
      mastercard: false,
      visa: ""
    }
  }),

  components: {
    KTPortlet
  },
  methods: {
    showDialog(item) {
      this.adddialog = true;
      if (item.id != null && item.id != undefined && item.id != null) {
        this.payment_option = item;
        this.edit = true;
      }
    },
    close() {
      this.adddialog = false;
      this.edit = false;
      this.$emit("initOrganisation");
    },
    saveOrupdate() {
      this.isBusy = true;
      if (!this.edit) {
        this.save();
      } else {
        this.update();
      }
    },

    save() {
      accptedPayment
        .create(this.slug, this.payment_option)
        .then(res => {
          this.$emit("initOrganisation");
          this.adddialog = false;
          this.isBusy = false;
          this.$snotify.success("Accepted payments updated");
        }).catch(err => {
      }).finally(() => (this.isBusy = false));
    },
    update() {
      accptedPayment
        .update(this.slug,this.payment_option.id,  this.payment_option)
        .then(res => {
          this.$emit("initOrganisation");
          this.adddialog = false;
          this.edit = false;
          this.isBusy = false;
          this.$snotify.success("Accepted payments updated");
        }).catch(err => {
      }).finally(() => (this.isBusy = false));
    },
  }
};
</script>
