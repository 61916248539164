<template>
    <div>
        <KTPortlet v-bind:title="'Awards and Achievements'" v-if="awards ? awards.length>0 : 0">
            <template v-slot:toolbar>
                <v-btn
                    @click="showDialog"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                    style="background:transparent;"
                >
                    <i class="flaticon-add-circular-button"></i>
                </v-btn>
            </template>
            <template v-slot:body>
                <v-app>
                    <!-- <draggable v-model="awards">
                    <transition-group>-->
                    <v-expansion-panels focusable>
                        <v-expansion-panel v-for="(item,i) in awards" :key="i" class="faq-list">
                            <v-expansion-panel-header class="font-weight-regular">
                                {{ item.title}}
                                <span class="text-right">
                  <!-- <a href="#" v-if="item.is_active" class="p-r-2">
                        <i class="flaticon2-check-mark kt-font-success"></i>
                  </a>-->
                  <a href="#" @click="editAward(item)" class="ps-1">
                    <img src="@/assets/media/icons/edit-tools.png" class="edit_icon"/>
                  </a>
                  <a href="#" @click="deleteAward(item.id)" class="ps-1">
                    <img src="@/assets/media/icons/send-to-trash.png" class="delete_icon"/>
                  </a>
                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="font-weight-regular">{{ item.description }}
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- </transition-group>
                    </draggable>-->
                </v-app>
            </template>
        </KTPortlet>
        <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower" v-else>
            <div class="kt-portlet__body">
                <div class="kt-iconbox__body">
                    <div class="kt-iconbox__icon">
                        <i class="flaticon2-cup kt-font-success kt-font-big"></i>
                    </div>
                    <div class="kt-iconbox__desc">
                        <h3 class="kt-iconbox__title">
                            <a class="kt-link" href="#">Showcase your Business</a>
                        </h3>
                        <div class="kt-iconbox__content">
                            <a href="#" @click="showDialog">Add Awards & Achievements</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog v-model="adddialog" persistent max-width="900px">
            <v-card>
                <v-card-title>
                    <span class="headline">Awards & Achievements</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="form-block" v-for="(award, index) in newAwards" :key="index">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-text-field label="Title" v-model="award.title" required outlined
                                                  densed></v-text-field>
                                    <p
                                        class="text-danger font-weight-bold"
                                        v-if="$v.newAwards.$each[index].title.$error"
                                    >Title is required</p>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-textarea
                                        label="Description"
                                        auto-grow="true"
                                        row="1"
                                        row-height="30px"
                                        v-model="award.description"
                                        required
                                        outlined
                                        densed
                                    ></v-textarea>
                                    <p
                                        class="text-danger font-weight-bold"
                                        v-if="$v.newAwards.$each[index].description.$error"
                                    >Description is required</p>
                                </v-col>
                                <v-col cols="2" md="2">
                                    <v-text-field label="Position" v-model="award.sort_position" required outlined
                                                  densed></v-text-field>
                                    <p
                                        class="text-danger font-weight-bold"
                                        v-if="$v.newAwards.$each[index].sort_position.$error"
                                    >Postition is required</p>
                                </v-col>
                                <v-col cols="2" md="2">
                                    <v-switch label="Active" v-model="award.is_active"></v-switch>
                                </v-col>
                                <!-- <v-col cols="12" md="12">
                                  <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    x-small
                                    color="red"
                                    v-if="index!=0"
                                    @click="removeAward(index)"
                                  >
                                    <v-icon dark>mdi-minus</v-icon>
                                  </v-btn>
                                  <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    x-small
                                    v-if="index===newAwards.length-1 && !edit"
                                    color="green"
                                    @click="addAward"
                                  >
                                    <v-icon dark>mdi-plus</v-icon>
                                  </v-btn>
                                </v-col> -->
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                    <v-btn color="white--text v-btn theme--light elevation-2 v-size--large primary"
                           class="btn-save-popup" :loading="isBusy" text @click.prevent="saveAwards">Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import NotifyService from "@/common/notify.service";
    import OgranisationAwardService from "@/services/organisation/OrganisationAwardService";

    const ogranisationAward = new OgranisationAwardService();
    import draggable from "vuedraggable";

    export default {
        name: "Awards",
        props: ["slug", "awards"],
        validations: {
            newAwards: {
                required,
                $each: {
                    description: {required},
                    title: {required},
                    sort_position: {required}
                }
            }
        },
        data: () => ({
            isBusy: false,
            adddialog: false,
            edit: false,
            tempAwards: [],
            newAwards: [{title: "", description: "", sort_position: 1, is_active: 1}]
        }),
        components: {
            KTPortlet,
            draggable
        },
        created() {
        },
        beforeUpdate() {
            // this.newAwards = this.awards;
        },
        methods: {
            showDialog() {
                this.adddialog = true;
            },
            close() {
                this.adddialog = false;
                this.resetAwards();
                this.$emit("initOrganisation");
            },
            addAward() {
                this.newAwards.push({
                    title: "",
                    description: "",
                    sort_position: 1,
                    is_active: 1
                });
            },
            removeAward(index) {
                this.newAwards.splice(index, 1);
            },
            saveAwards() {
                this.$v.$touch();
                if (this.$v.newAwards.$error) {

                } else {
                    this.isBusy = true;
                    if (!this.edit) {
                        this.save();
                    } else {
                        this.update();
                    }
                }
            },
            resetAwards() {
                this.newAwards = [
                    {title: "", description: "", sort_position: 1, is_active: 1}
                ];
            },

            save() {
                ogranisationAward
                    .saveMultiple(this.slug, this.newAwards)
                    .then(res => {
                        this.resetAwards();
                        this.$emit("initOrganisation");
                        this.adddialog = false;
                        this.isBusy = false;
                        this.$snotify.success("Award added");
                    })
                    .catch(err => {
                    }).finally(() => (this.isBusy = false));
            },
            update() {
                ogranisationAward
                    .update(this.slug, this.newAwards[0].id, this.newAwards[0])
                    .then(res => {
                        this.resetAwards();
                        this.$emit("initOrganisation");
                        this.adddialog = false;
                        this.edit = false;
                        this.isBusy = false;
                        this.$snotify.success("Award updated");
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            editAward: function (item) {
                this.adddialog = true;
                this.edit = true;
                this.newAwards = [];
                this.newAwards.push(item);
            },
            // Delete Faq
            deleteAward(id) {
                this.$confirm(
                    {
                        message: `Confirm delete award ? `,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                ogranisationAward
                                    .delete(this.slug, id)
                                    .then(res => {
                                        this.$emit("initOrganisation");
                                        this.faqdialog = false;
                                        this.$snotify.success("Award deleted");
                                    })
                                    .catch(err => {
                                    });
                            }
                        }
                    }
                )

            }
        }
    };
</script>

<style>
    /* .theme--light.v-expansion-panels .v-expansion-panel {
      background-color: white;
      border: 0.5px solid white;
      color: rgba(0, 0, 0, 0.87);
    } */
</style>
