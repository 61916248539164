          <template>
  <div class="container-fluid noarrow">
    <div class="row" v-if="!loading">
      <div class="col-md-12">
        <info
          :organisation="organisation"
          :addresses="organisation.addresses"
          :slug="slug"
          :review="reviewsCount"
          @initOrganisation="initOrganisation"
        ></info>
      </div>
    </div>
    <div class="row" v-if="!loading">
      <div class="col-md-8">
        <description :organisation="organisation" :slug="slug" @initOrganisation="initOrganisation"></description>
        <faq :faqs="organisation.faqs" :slug="slug" @initOrganisation="initOrganisation"></faq>
        <award :awards="organisation.awards" :slug="slug" @initOrganisation="initOrganisation"></award>
        <locations
          :slug="slug"
          :locations="organisation.addresses"
          @initOrganisation="initOrganisation"
        ></locations>
        <div>
          <v-app>
            <v-card
              :loading="loading"
              class="mx-auto mb-3"
              v-for="(review,index) in reviews"
              raised
              width="820"
              :key="index"
            >
              <v-card-title>{{ review.reviewed_by.first_name }} {{ review.reviewed_by.last_name }}</v-card-title>

              <v-card-text>
                <v-row allign="right" class="mx-0">
                  <v-rating
                    :value="Number(review.rating)"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>
                  <div class="subtitle-2 mr-5">{{ review.reviewed_at}}</div>
                </v-row>
                <div class="my-4 headline">{{review.title}}</div>

                <div>{{review.description}}</div>
              </v-card-text>
            </v-card>

            <v-row v-if="reviews.lenght>0">
              <v-col cols="12" sm="12" md="12">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
                  @input="getReviews(page)"
                  :total-visible="5"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-app>
        </div>
      </div>
      <div class="col-md-4">
        <businessHour
          :exception="organisation.opening_hour_exceptions"
          :business_hour="organisation.opening_hour"
          :slug="slug"
          @initOrganisation="initOrganisation"
        ></businessHour>
        <social
          :attribute="organisation.attribute"
          :slug="slug"
          @initOrganisation="initOrganisation"
        ></social>
        <tags :tags="organisation.tags" :slug="slug" @initOrganisation="initOrganisation" ref="tag"></tags>
        <services
          :services="organisation.services"
          :slug="slug"
          @initOrganisation="initOrganisation"
        ></services>
        <payementOption
          :apayments="organisation.accepted_payment"
          :slug="slug"
          @initOrganisation="initOrganisation"
        ></payementOption>
        <externalLinks
          :links="organisation.external_links"
          :slug="slug"
          @initOrganisation="initOrganisation"
        ></externalLinks>
        <gallary></gallary>
      </div>
    </div>
    <v-app>
      <div class="row">
        <div class="col-md-12">
          <v-skeleton-loader v-if="loading" height="94" type="card-avatar"></v-skeleton-loader>
          <v-skeleton-loader v-if="loading" height="94" type="list-item-avatar-three-line"></v-skeleton-loader>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <v-skeleton-loader v-if="loading" height="94" type="list-item-two-line"></v-skeleton-loader>
          <v-skeleton-loader v-if="loading" height="94" type="article"></v-skeleton-loader>
          <v-skeleton-loader v-if="loading" height="94" type="list-item-two-line"></v-skeleton-loader>

          <div>
            <v-skeleton-loader v-if="loading" height="94" type="article"></v-skeleton-loader>
          </div>
        </div>
        <div class="col-md-4">
          <v-skeleton-loader v-if="loading" height="94" type="table"></v-skeleton-loader>
          <v-skeleton-loader v-if="loading" height="94" type="card-heading"></v-skeleton-loader>

          <v-skeleton-loader v-if="loading" height="94" type="table-head"></v-skeleton-loader>

          <v-skeleton-loader v-if="loading" height="94" type="list-item-avatar"></v-skeleton-loader>

          <v-skeleton-loader v-if="loading" height="94" type="card-heading"></v-skeleton-loader>
        </div>
      </div>
    </v-app>
  </div>
</template>
          <script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Info from "./profile/Info";
import Description from "./profile/Description";
import BusinessHour from "./profile/BusinessHour";
import Services from "./profile/Services";
import Tags from "./profile/Tags";
import Social from "./profile/Social";

import PayementOption from "./profile/PaymentOption";
import ExternalLinks from "./profile/Links";
import Faq from "./profile/Faq";
import Gallary from "./profile/Gallary";
import Award from "./profile/Award";
import Locations from "./profile/Locations";
import OgranisationService from "@/services/organisation/OrganisationService";
const ogranisationService = new OgranisationService();
import OgranisationReviewService from "@/services/organisation/OrganisationReviewService";
const ogranisationReview = new OgranisationReviewService();
export default {
  name: "organisationProfile",

  data: () => ({
    adddialog: false,
    organisation: {},
    reviews: [],
    loading: true,
    selection: 1,
    itemsPerPage: 5,
    reviewsCount: 0,
    page: null,
    pageCount: null,
    per_page: null
  }),
  components: {
    KTPortlet,
    Info,
    Description,
    Services,
    BusinessHour,
    PayementOption,
    ExternalLinks,
    Faq,
    Award,
    Gallary,
    Locations,
    Tags,
    Social
  },
  methods: {},
  mounted() {
    this.initOrganisation();
    this.getReviews();
  },
  computed: {
    slug() {
      return this.$route.params.name;
    }
  },
  methods: {
    showDialog() {
      this.adddialog = true;
    },
    getReviews(index = null) {
      ogranisationReview.paginateByOrg(this.slug, index).then(response => {
        this.reviews = response.data.data;
        this.reviewsCount = response.data.meta.total;
        this.itemsPerPage = response.data.meta.per_page;
        this.page = response.data.meta.current_page;
        this.pageCount = response.data.meta.last_page;
      });
    },
    initOrganisation() {
      ogranisationService
        .show(this.slug)
        .then(res => {
          this.organisation = res.data.organisation;
          this.loading = false;
          this.$refs.tag.getTags();
        })
        .catch(err => {});
    }
  }
};
</script>

          <style  >


.kt-portlet {
  margin-bottom: 10px !important;
}
</style>
