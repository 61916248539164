<template>
  <div>
    <KTPortlet v-bind:title="'Tags'" v-if="tags ? tags.length>0 : 0">
      <template v-slot:toolbar>
        <v-btn
          @click="showDialog"
          class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
          style="background:transparent;"
        >
          <i class="flaticon-edit"></i>
        </v-btn>
      </template>
      <template v-slot:body>
        <div class="kt-notification kt-notification--fit">
          <span v-for="(tag,index) in tags" :key="index">
            <v-chip
              v-if="tag.title"
              class="ps-3"
              :key="tag.id"
              text-color="white"
              close
              close-icon="mdi-close text-danger"
              @click:close="deleteTag(tag.id)"
            >{{ tag.title }}</v-chip>
          </span>
        </div>
      </template>
    </KTPortlet>

    <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower" v-else>
      <div class="kt-portlet__body">
        <div class="kt-iconbox__body">
          <div class="kt-iconbox__icon">
            <i class="flaticon-presentation kt-font-success kt-font-big"></i>
          </div>
          <div class="kt-iconbox__desc">
            <h3 class="kt-iconbox__title">
              <a class="kt-link" href="#">Tags</a>
            </h3>
            <div class="kt-iconbox__content">
              <a href="#" @click="showDialog">Right Service tag help you find more on search</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="adddialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Tags</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="form-block">
              <v-row>
                <v-col cols="12" md="12">
                  <v-combobox
                    v-model="tagsValue"
                    :items="tagList"
                    :search-input.sync="search"
                    hide-selected
                    label="Add Tags"
                    multiple
                    item-text="title"
                    item-value="id"
                    persistent-hint
                    small-chips
                    outlined
                    densed
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "
                            <strong>{{ search }}</strong>". Press
                            <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                      <p
                    class="text-danger font-weight-bold"
                    v-if="$v.tagsValue.$error"
                  >Tags is required</p>
                </v-col>
              </v-row>
            </div>
            <!-- <pre>
              {{ newtags }}
            </pre>-->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="adddialog = false">Close</v-btn>
          <v-btn
            color="white--text v-btn theme--light elevation-2 v-size--large primary"
            class="btn-save-popup"
            :loading="isBusy"
            text
            @click.prevent="addTags"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import NotifyService from "@/common/notify.service";
import OrganisationTagService from "@/services/organisation/OrganisationTagService";
import DirectoryTagService from "@/services/directory/DirectoryTagService";
import { required } from "vuelidate/lib/validators";

const services = new OrganisationTagService();

export default {
  name: "Tags",
  props: ["slug", "tags"],
  validations: {
    tagsValue: { required }
  },
  data: () => ({
    isBusy: false,
    tagList: [],
    adddialog: false,
    tagsValue: [],
    search: null,
    serviceItems: [],
    newtags: []
  }),
  components: {
    KTPortlet
  },
  mounted() {
    this.getTags();
  },
  methods: {
    showDialog() {
      this.adddialog = true;
    },
    isObject(arg) {
      return arg !== null && typeof arg === "object";
    },
    addTags() {
      this.$v.$touch();
      if (!this.$v.tagsValue.$error) {
        this.$v.$reset();
        this.tagsValue.filter((value, index) => {
          if (this.isObject(value)) {
            this.newtags.push({
              title: value.title,
              directory_service_id: value.id,
              sort_position: 1,
              is_active: 1
            });
          } else {
            this.newtags.push({
              title: value,
              directory_service_id: null,
              sort_position: 1,
              is_active: 1
            });
          }
        });
        this.saveTags();
      }
    },
    saveTags() {
      this.isBusy = true;
      services
        .saveMultiple(this.slug, this.newtags)
        .then(res => {
          this.tagsValue = [];
          this.newtags = [];
          this.adddialog = false;
          this.$emit("initOrganisation");
          this.isBusy = false;
          this.$snotify.success("Tag added");
          this.getTags();
        }).catch(err => {
      }).finally(() => (this.isBusy = false));
    },
    deleteTag(id) {
        this.$confirm(
            {
                message: `Confirm delete tag ?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        services
                            .delete(this.slug, id)
                            .then(res => {
                                this.$emit("initOrganisation");
                                this.adddialog = false;
                                this.$snotify.success("Tag deleted");
                            })
                            .catch(err => {});
                    }
                }
            }
        )

    },
    getTags() {
      services
        .getSuggestionList(this.slug)
        .then(response => {
          this.tagList = response.data;
        })
        .catch(error => {});
    }
  }
};
</script>
