import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class DirectoryTagService {

      getSuggestionList(){
            let url = API_URL + 'user/directory-tag/list';
            return apiService.get(url);
      }
}
