import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class OrganisationTagService {


    saveMultiple(slug, data) {
        let url = API_URL + 'user/organisation/' + slug + '/tag/store/many';
        return apiService.post(url, data);
    }


    create(slug, data) {
        let url = API_URL + 'user/organisation' + slug + '/tag'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/organisation/' + slug + '/tag/' + id
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/organisation/' + slug + '/tag/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/organisation/' + slug + '/tag/' + id
        return apiService.get(url);
    }

    getSuggestionList(slug) {
        let url = API_URL + 'user/organisation/' + slug + '/tag-list'
        return apiService.get(url);
    }


}
