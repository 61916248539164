<template>
    <div class="kt-portlet kt-portlet--height-fluid">
        <div class="kt-portlet__body">
            <div class="kt-widget kt-widget--user-profile-3">
                <div class="kt-widget__top">
                    <div class="kt-avatar kt-avatar--outline" id="kt_user_avatar_1">
                        <img
                            class="kt-avatar__holder"
                            style="object-fit: contain"
                            v-bind:src="organisation.attribute ? organisation.attribute.logo_image_path : '/assets/media/logos/icon-no-image.svg'"
                            alt="image"
                        />
                        <label class="kt-avatar__upload" @click="showLogoDialog">
                            <i class="fa fa-pen"></i>
                        </label>
                        <a href="#" style="display:block;" @click="showCoverDialog">Change Cover</a>
                    </div>

                    <div
                        class="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-boldest kt-font-light kt-hidden"
                    >JM
                    </div>
                    <div class="kt-widget__content">
                        <div class="kt-widget__head">
                            <a href="#" class="kt-widget__username">
                                <span class="mr-3">{{ organisation.title }}</span>
                                <i
                                    v-if="organisation.is_verified"
                                    class="flaticon2-correct"
                                ></i>
                                <button class=" btn btn-clean btn-sm btn-icon-md btn-transparent"
                                        v-else-if="organisation.verification && organisation.verification.submission_status == 'pending'">
                                    <i class="fas fa-clock text-primary" title="Verification Pending"></i>
                                    Under Review
                                </button>
                                <button
                                    v-else
                                    @click="showVerifyDialog"
                                    class=" btn btn-clean btn-sm btn-icon-md btn-transparent"
                                ><i class="flaticon-warning-sign text-warning"></i> Verify your business
                                </button>

                            </a>

                            <div class="kt-widget__action">
                                <div class="kt-portlet__head-toolbar">
                                    <!-- || !organisation.subscription.status || organisation.subscription.remaining<30 -->
                                        <!-- v-if="!organisation.active_cart && (!organisation.subscription.status || organisation.subscription.remaining<30)" -->
                                    <button
                                        class="btn btn-primary text-white btn-sm btn-upper"
                                        @click="organisationSubscription(organisation.slug)">
                                        <i class="fa fa-dollar-sign"></i> Subscribe to a plan & unlock all features
                                    </button>
                                    <div class="dropdown dropdown-inline">
                                        <v-btn
                                            @click="showDialog"
                                            class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                                            style="background:transparent;"
                                        >
                                            <i class="flaticon-edit"></i>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="kt-widget__subhead">
              <span v-for="item in addresses" :key="item.id">
                <a href="#" v-if="item.is_primary">
                  <i class="flaticon2-pin"></i>
                  {{ (item.address_line_1)?item.address_line_1+', ':'' }}
                  {{ (item.area)?item.area+",":'' }}
                  {{ (item.city)?item.city+',':'' }}
                  {{ (item.state)?item.state+"":'' }}
                </a>
              </span>
                        </div>
                        <div class="kt-widget__subhead">

                            <a href="#">
                                <i class="flaticon2-phone"></i>
                                {{ organisation.phone ? organisation.phone : 'Add Phone' }}
                            </a>
                            <a href="#" v-if="organisation.mobile">
                                <i class="flaticon2-phone"></i>
                                {{ organisation.mobile ? organisation.mobile : 'Add Mobile' }}
                            </a>
                            <a href="#">
                                <i class="flaticon2-new-email"></i>
                                {{ organisation.email ? organisation.email : 'Add Email' }}
                            </a>
                            <a href="#">
                                <i class="flaticon2-website"></i>
                                {{ organisation.website ? organisation.website : 'Add Website' }}
                            </a>
                        </div>
                        <div class="kt-widget__subhead">
                            <a href="#" v-if="organisation.alt_email">
                                <i class="flaticon2-new-email"></i>
                                {{ organisation.alt_email ? organisation.alt_email : 'Add Second Email' }}
                            </a>
                            <a href="#" v-if="organisation.alt_website">
                                <i class="flaticon2-phone"></i>
                                {{ organisation.alt_website ? organisation.alt_website : 'Add Second Website' }}
                            </a>
                        </div>

                        <!-- <pre>{{organisation}}</pre> -->
                        <!-- <div class="kt-widget__info">
                          <div class="kt-widget__desc" v-html="organisation.description"></div>
                        </div>-->
                    </div>
                </div>
                <v-app>
                    <div class="kt-widget__bottom">
                        <div class="kt-widget__item">
                            <div class="kt-widget__icon">
                                <i class="flaticon-event-calendar-symbol"></i>
                            </div>
                            <div class="kt-widget__details">
                                <span class="kt-widget__title">Established Year</span>

                                <span
                                    class="kt-widget__value"
                                >{{ organisation.attribute ? organisation.attribute.established_year : 'N/A' }}</span>
                            </div>
                        </div>
                        <div class="kt-widget__item">
                            <div class="kt-widget__icon">
                                <i class="flaticon-users"></i>
                            </div>
                            <div class="kt-widget__details">
                                <span class="kt-widget__title">Employee Size</span>
                                <span
                                    class="kt-widget__value"
                                >{{ organisation.attribute ? organisation.attribute.employee_size : 'N/A'}}</span>
                            </div>
                        </div>
                        <div class="kt-widget__item">
                            <div class="kt-widget__icon">
                                <i class="flaticon-pie-chart"></i>
                            </div>
                            <div class="kt-widget__details">
                                <span class="kt-widget__title">Profile Views</span>
                                <span class="kt-widget__value">
                  <span></span>{{ organisation.views ? organisation.views : 'N/A'}}
                </span>
                            </div>
                        </div>
                        <!-- <div class="kt-widget__item">
                          <div class="kt-widget__icon">
                            <i class="flaticon-file-2"></i>
                          </div>
                          <div class="kt-widget__details">
                            <span class="kt-widget__title">73 Booking</span>
                            <a href="#" class="kt-widget__value kt-font-brand">View</a>
                          </div>
                        </div> -->
                        <div class="kt-widget__item">
                            <div class="kt-widget__icon">
                                <i class="flaticon-chat-1"></i>
                            </div>
                            <div class="kt-widget__details">
                                <span class="kt-widget__title">{{ review }} Reviews</span>
                                <!-- <a href="#" class="kt-widget__value kt-font-brand">View</a> -->
                            </div>
                        </div>
                        <div class="kt-widget__item">
                            <div class="kt-widget__icon">
                                <i class="flaticon-chat-1"></i>
                            </div>
                            <div class="kt-widget__details">
                                <span class="kt-widget__title">Setup Review</span>
                                <a
                                    href="#"
                                    class="kt-widget__value kt-font-brand"
                                    @click="showReviewQuestionDialog"
                                >Questionnaire</a>
                            </div>
                        </div>

                        <div class="kt-widget__action">
                            <div class="kt-portlet__head-toolbar">
                                <div class="dropdown dropdown-inline">
                                    <v-btn
                                        @click="showAttributeDialog"
                                        class="btn btn-clean btn-sm btn-icon btn-icon-md btn-transparent"
                                        style="background:transparent;"
                                    >
                                        <i class="flaticon-edit"></i>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-app>
            </div>
        </div>
        <v-app>
            <v-dialog v-model="adddialog" persistent max-width="900px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Business Information</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form v-model="valid" ref="organisation" lazy-validation>
                                <div class="form-block">
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                v-model="organisation.title"
                                                label="Title"
                                                persistent-hint
                                                :error-messages="nameErrors"
                                                @blur="$v.organisation.title.$touch()"
                                                hint="Eg: Yelko Web Pvt. Ltd."
                                                outlined
                                                densed
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                v-model="organisation.tag_line"
                                                label="Tag Line"
                                                persistent-hint
                                                hint="Eg: Powering Business, Informations & Websites"
                                                outlined
                                                densed
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                v-model="organisation.phone"
                                                :error-messages="phoneErrors"
                                                @blur="$v.organisation.phone.$touch()"
                                                label="Phone"
                                                persistent-hint
                                                hint="Eg: 9823428000 or 01-4358xxx"
                                                outlined
                                                densed
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                v-model="organisation.mobile"
                                                label="Mobile"
                                                persistent-hint
                                                hint="Eg: 9823428000"
                                                outlined
                                                densed
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                v-model="organisation.email"
                                                label="Primary Email"
                                                :error-messages="emailErrors"
                                                @blur="$v.organisation.email.$touch()"
                                                persistent-hint
                                                hint="Eg: contact@yelko.com"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                v-model="organisation.alt_email"
                                                label="Secondary Email"
                                                persistent-hint
                                                hint="Eg: support@yelko.com"
                                                outlined
                                                densed
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                v-model="organisation.website"
                                                label=""
                                                persistent-hint
                                                hint="Eg: www.yelko.com"
                                                prefix="www."
                                                outlined
                                                densed
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field
                                                v-model="organisation.alt_website"
                                                label=""
                                                prefix="www."
                                                persistent-hint
                                                hint="Eg: www.yelko.net"
                                                outlined
                                                densed
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                            <!-- <pre> {{organisation}}</pre> -->
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1 " text @click="cancelDialog">Cancel</v-btn>
                        <v-btn color="white--text v-btn theme--light elevation-2 v-size--large primary"
                               class="btn-save-popup" :loading="isBusy" text @click.prevent="updateOrganisation">Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="logodialog" persistent max-width="900px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Change Business Logo</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form v-model="valid" ref="organisation" lazy-validation>
                                <div class="form-block">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-file-input
                                                type="file"
                                                ref="file"
                                                @change="uploadImage($event)"
                                                accept="image/*"
                                                placeholder="Upload business logo"
                                                prepend-inner-icon="mdi-camera"
                                                label="Logo"
                                                outlined
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12" class="p-4">
                                            <Cropper
                                                v-if="image"
                                                class="upload-example-cropper"
                                                :src="image"
                                                :backgroundClassname="'white-background'"
                                                :stencil-props="{
                              previewClassname: 'preview',
                            }"

                                                :imageRestriction="imageRestriction"
                                                @change="change"
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                            <!-- <pre> {{updateAttribute}}</pre> -->
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="cancelLogoDialog">Cancel</v-btn>
                        <v-btn color="blue darken-1" :loading="isBusy" text @click.prevent="uploadLogo">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="coverDialog" persistent max-width="900px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Change Cover Image</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form v-model="valid" ref="organisation" lazy-validation>
                                <div class="form-block">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-file-input
                                                type="file"
                                                ref="file"
                                                @change="uploadImage($event)"
                                                accept="image/*"
                                                placeholder="Upload Cover Image"
                                                prepend-inner-icon="mdi-camera"
                                                label="Cover Image"
                                                outlined
                                            ></v-file-input>
                                        </v-col>

                                        <v-col cols="12" md="12">
                                            <Cropper
                                                v-if="image"
                                                class="upload-example-cropper"
                                                :backgroundClassname="'white-background'"
                                                :src="image"
                                                :stencil-props="{
                              aspectRatio: 16/9
                            }"
                                                :imageRestriction="imageRestriction"
                                                @change="changeCoverImage"
                                            />
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                            <!-- <pre> {{organisation}}</pre> -->
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="canceCoverDialog">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click.prevent="uploadCoverImage" :loading="isBusy">Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="verifyDialog" persistent max-width="900px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Business verification </span>

                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-col cols="12" md="12">

                                <p>To verify your business, we will need your business documents.
                                    It may take upto 2-3 business days to verify your information.</p>
                                <p>
                                <ul>
                                    <li>Business Registration Certificate</li>
                                    <li>Tax Clearance Certificate</li>
                                    <li>Request Letter</li>
                                </ul>
                                </p>

                                <p>Please upload one or all of above document to submit your verification request.</p>
                            </v-col>
                            <v-form ref="verification" lazy-validation>
                                <div class="form-block">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-file-input
                                                v-model="verification.doc_one"
                                                accept="image/*, .pdf"
                                                type="file"
                                                ref="file"
                                                placeholder="Upload file"
                                                prepend-inner-icon="attach_file"
                                                label="Company Registration Certificate"
                                                outlined
                                                densed
                                            ></v-file-input>
                                            <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.verification.doc_one.$error"
                                            >File cannot be empty</p>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-file-input
                                                v-model="verification.doc_two"
                                                accept="image/*, .pdf"
                                                type="file"
                                                ref="file"
                                                placeholder="Upload file"
                                                prepend-inner-icon="attach_file"
                                                label="Tax Clearance Certificate"
                                                outlined
                                                densed
                                            ></v-file-input>
                                            <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.verification.doc_two.$error"
                                            >File cannot be empty</p>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-file-input
                                                v-model="verification.doc_three"
                                                accept="image/*, .pdf"
                                                type="file"
                                                ref="file"
                                                placeholder="Upload File"
                                                prepend-inner-icon="attach_file"
                                                label="Request Letter"
                                                outlined
                                                densed
                                            ></v-file-input>
                                            <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.verification.doc_three.$error"
                                            >File cannot be empty</p>
                                        </v-col>

                                        <v-col cols="12" md="6">
                                            <v-file-input
                                                v-model="verification.doc_four"
                                                accept="image/*, .pdf"
                                                type="file"
                                                ref="file"
                                                placeholder="Upload file"
                                                prepend-inner-icon="attach_file"
                                                label="Additional Document"
                                                outlined
                                                densed
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                            <!-- <pre> {{organisation}}</pre> -->
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeeverifyDialog">Cancel</v-btn>
                        <v-btn color="white--text v-btn theme--light elevation-2 v-size--large primary"
                               class="btn-save-popup" text @click.prevent="verifyNow">Submit Request
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="reviewQuestionDialog" persistent max-width="900px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Review manager.</span>
                    </v-card-title>
                    <v-card-text>
                        <v-list dense>
                            <p>Setup questions to review your business. These questions are provided to user for
                                review. </p>
                            <v-list-item-group color="primary">
                                <v-list-item v-for="(item,i) in questionnaireData" :key="i">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ ++i }}. {{ item.title}}
                                            <span class="pull-right">
                        <span v-if="item.is_active"><i class="flaticon2-check-mark kt-font-success"></i> Approved & Active  <img
                            src="@/assets/media/icons/edit-tools.png" class="edit_icon"
                            @click="editQuestionnaire(item)"/></span>
                        <span v-else> <i
                            class="fas fa-exclamation-triangle kt-font-warning"></i>  Pending approval </span>
                      </span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                    <v-card-text>
                        <v-container>
                            <v-form ref="verification" lazy-validation>
                                <div class="form-block">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <h4>Add review question </h4>
                                            <v-text-field v-model="questionnaire.title" label="Question title"
                                                          outlined></v-text-field>
                                            <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.questionnaire.title.$error"
                                            >Question title is required.</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                            <p class="text-black">
                <span>Questions added will be reviewed by our support team and will be approved only if appropriate.
                  Once approved, it is not allowed to update the questions.</span>
                            </p>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeReviewQuestionDialog">Cancel</v-btn>
                        <v-btn
                            v-if="!questionnaireUpdate"
                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                            class="btn-save-popup"
                            text
                            @click.prevent="addQuestionnaire"
                        >Save
                        </v-btn>
                        <v-btn v-else color="white--text v-btn theme--light elevation-2 v-size--large primary"
                               class="btn-save-popup" text @click.prevent="updateQuestionnaire">Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="attributeDialog" persistent max-width="900px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Business Information</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form v-model="valid" ref="attribute_object" lazy-validation>
                                <div class="form-block">
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-menu
                                                v-model="datepicker"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="date"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"

                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="attribute_object.established_year"
                                                        label="Established year"
                                                        prepend-inner-icon="event"
                                                        readonly
                                                        v-on="on"
                                                        outlined
                                                        densed
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="attribute_object.established_year"
                                                    type="month"
                                                    :max="getEndDate"
                                                    @input="datepicker = false"
                                                    prepend-inner-icon="profile"
                                                    outlined
                                                    densed
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select
                                                :items="employee_size"
                                                v-model="attribute_object.employee_size"
                                                label="Employee Size"
                                                outlined
                                                densed
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" sm="6" md="6">
                                            <v-text-field
                                                v-model="attribute_object.featured_video_url"
                                                label="Featured Video URL"
                                                outlined
                                                densed
                                            ></v-text-field>
                                            <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.attribute_object.featured_video_url.$error"
                                            >Not a valid url</p>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="6">
                                            <v-text-field
                                                v-model="attribute_object.featured_link_redirect"
                                                @blur="$v.attribute_object.featured_link_redirect.$touch()"
                                                label="Featured Video Redirect URL"
                                                outlined
                                                densed
                                            ></v-text-field>
                                            <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.attribute_object.featured_link_redirect.$error"
                                            >Not a valid url</p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-switch
                                                v-model="attribute_object.has_opening_hours"
                                                label="Display Opening Hours"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-switch
                                                v-model="attribute_object.booking_enabled"
                                                label="Accept Online Booking "
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-switch v-model="attribute_object.has_branch"
                                                      label="Diplay Branch Office "></v-switch>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-switch
                                                v-model="attribute_object.has_featured_video"
                                                label="Display Featured Video "
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                            <!-- <pre>
                            {{attribute_object}}</pre>-->
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="cancelAttributeDialog">Cancel</v-btn>
                        <v-btn color="white--text v-btn theme--light elevation-2 v-size--large primary"
                               class="btn-save-popup" :loading="isBusy" text @click.prevent="submitUpdateAttribute">Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
        <!-- <membership :membershipDialog="membershipDialog" @membership_dialog_close="closeUpgradeDialog"></membership> -->
    </div>
</template>

<script>
    import {validationMixin} from "vuelidate";
    import {
        email,
        required,
        minLength,
        sameAsm,
        url
    } from "vuelidate/lib/validators";
    import NotifyService from "@/common/notify.service";
    import OgranisationService from "@/services/organisation/OrganisationService";
    import OrganisationAttributeService from "@/services/organisation/OrganisationAttributeService";
    import OrganisationVerificationService from "@/services/organisation/OrganisationVerificationService";
    import QuestionnaireService from "@/services/directory/review/QuestionnaireService";
    import CKEditor from "ckeditor4-vue";
    import {isPhoneNumner} from "@/common/validation";
    import {Cropper} from "vue-advanced-cropper";

    const ogranisationService = new OgranisationService();
    const attributeService = new OrganisationAttributeService();
    const verificationService = new OrganisationVerificationService();
    const questionnaireService = new QuestionnaireService();

    export default {
        name: "Info",
        props: ["slug", "organisation", "addresses", "review"],
        mixins: [validationMixin],
        validations: {
            organisation: {
                title: {required},
                phone: {required, isPhoneNumner},
                email: {required, email}
            },
            attribute_object: {
                featured_video_url: {url},
                featured_link_redirect: {url}
            },
            verification: {
                doc_one: {required},
                doc_two: {required},
                doc_three: {required},
            },
            questionnaire: {
                title: {required}
            }
        },
        components: {
            Cropper,
            ckeditor: CKEditor.component,
        },
        data: () => ({
            isBusy: false,
            date: new Date().toISOString().substr(0, 7),
            newDate: new Date(),
            menu: false,
            datepicker: false,
            attributeDialog: false,
            verifyDialog: false,
            reviewQuestionDialog: false,
            membershipDialog: false,
            adddialog: false,
            coverDialog: false,
            valid: false,
            logodialog: false,
            editEstablishedDate: false,
            editEmployeeSize: false,
            plan: null,
            employee_size: ["1-5", "5-15", "15-30", "30-50", "50-150"],

            imageRestriction: "none",
            image: null,
            background: "white-background",
            attribute_object: {
                established_year: "",
                employee_size: "",
                featured_video_url: "",
                established_year: "",
                featured_link_redirect: "",
                has_opening_hours: 1,
                booking_enabled: 1,
                has_branch: 1,
                has_featured_video: 1
            },
            updateAttribute: {
                logo_image: "icon-no-image.svg"
            },
            verification: {
                doc_one: null,
                doc_two: null,
                doc_three: null,
                doc_four: null,
                doc_five: null,
                doc_six: null,
                doc_seven: null,
                doc_eight: null
            },
            questionnaire: {
                title: null,
                is_active: 0
            },
            questionnaireData: [],
            questionnaireUpdate: false,
            organisation_id: null
        }),
        created() {
            this.getQuestionnaires();
        },
        updated() {
        },
        methods: {
            showDialog() {
                this.adddialog = true;
            },
            showLogoDialog() {
                this.logodialog = true;
            },
            organisationSubscription(slug) {
                this.$router.push({name: 'organization-subscription', params: {'slug': slug}})
            },

            closeUpgradeDialog() {
                this.$emit("initOrganisation");
                this.membershipDialog = false;
            },

            showReviewQuestionDialog() {
                this.reviewQuestionDialog = true;
            },
            closeReviewQuestionDialog() {
                this.$emit("initOrganisation");
                this.reviewQuestionDialog = false;
                this.questionnaire = {
                    title: null
                };
                this.getQuestionnaires();
            },
            showVerifyDialog() {
                this.verifyDialog = true;
            },
            closeeverifyDialog() {
                this.$emit("initOrganisation");
                this.verifyDialog = false;
            },
            showCoverDialog() {
                this.coverDialog = true;
            },
            canceCoverDialog() {
                this.$emit("initOrganisation");
                this.coverDialog = false;
            },
            cancelDialog() {
                this.$emit("initOrganisation");
                this.adddialog = false;
            },
            cancelLogoDialog() {
                this.$emit("initOrganisation");
                this.logodialog = false;
            },
            showAttributeDialog() {
                this.attributeDialog = true;
                this.attribute_object = this.organisation.attribute;
                delete this.attribute_object.logo_image_thumb;
                delete this.attribute_object.logo_image;
            },
            cancelAttributeDialog() {
                this.$emit("initOrganisation");
                this.attributeDialog = false;
            },
            uploadFile() {
            },
            updateOrganisation() {
                this.$v.organisation.$touch();
                if (this.$v.organisation.$error) {
                } else {
                    //call Api Here
                    this.isBusy = true;
                    let organisation = this.sanitizeOrganisation(this.organisation);
                    ogranisationService
                        .update(this.slug, organisation)
                        .then(res => {
                            this.adddialog = false;
                            this.isBusy = false;
                            this.$emit("initOrganisation");
                            this.$snotify.success("Business information updated");
                        }).catch(err => {
                    }).finally(() => (this.isBusy = false));
                }
            },
            change({coordinates, canvas}) {
                this.updateAttribute.logo_image = canvas.toDataURL("image/png");
                // console.log(canvas.toDataURL("image/jpeg"));
            },
            changeCoverImage({coordinates, canvas}) {
                this.updateAttribute.cover_image = canvas.toDataURL("image/png");
                // console.log(canvas.toDataURL("image/jpeg"));
            },
            uploadImage(event) {
                if (event) {
                    var reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = e => {
                        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                        // Read image as base64 and set to imageData
                        this.image = e.target.result;
                    };
                    reader.readAsDataURL(event);
                }
            },
            uploadLogo() {
                delete this.updateAttribute.cover_image;
                this.isBusy = true;
                attributeService
                    .update(this.slug, this.organisation.attribute.id, this.updateAttribute)
                    .then(res => {
                        this.$emit("initOrganisation");
                        this.logodialog = false;
                        this.image = "null"
                        this.isBusy = false;
                        this.$snotify.success("Logo updated");
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            uploadCoverImage() {
                delete this.updateAttribute.logo_image;
                this.isBusy = true;
                attributeService
                    .update(this.slug, this.organisation.attribute.id, this.updateAttribute)
                    .then(res => {
                        this.$emit("initOrganisation");
                        this.coverDialog = false;
                        this.isBusy = false;
                        this.image = "null"
                        this.$snotify.success("Cover Image updated");
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            submitUpdateAttribute() {
                this.$v.attribute_object.$touch();
                if (!this.$v.attribute_object.$error) {
                    this.isBusy = true;
                    attributeService
                        .update(
                            this.slug,
                            this.organisation.attribute.id,
                            this.attribute_object
                        )
                        .then(res => {
                            this.$emit("initOrganisation");
                            this.attributeDialog = false;
                            this.isBusy = false;
                            this.$snotify.success("Information updated");
                        }).catch(err => {
                    }).finally(() => (this.isBusy = false));
                }
            },
            sanitizeOrganisation(organisation) {
                delete organisation.user;
                delete organisation.addresses;
                delete organisation.services;
                delete organisation.attribute;
                delete organisation.categories;
                delete organisation.tags;
                delete organisation.faqs;
                delete organisation.awards;
                delete organisation.accepted_payment;
                delete organisation.accepted_payment;
                delete organisation.external_links;
                delete organisation.opening_hour;
                delete organisation.opening_hour_exceptions;
                return organisation;
            },

            verifyNow() {
                this.$v.verification.$touch();
                if (this.$v.verification.$error) {
                    setTimeout(() => {
                        this.$v.verification.$reset();
                    }, 3000);
                } else {
                    let formData = new FormData();
                    formData.append(
                        "doc_one",
                        this.verification.doc_one ? this.verification.doc_one : ""
                    );
                    formData.append(
                        "doc_two",
                        this.verification.doc_two ? this.verification.doc_two : ""
                    );
                    formData.append(
                        "doc_three",
                        this.verification.doc_three ? this.verification.doc_three : ""
                    );
                    formData.append(
                        "doc_four",
                        this.verification.doc_four ? this.verification.doc_four : ""
                    );
                    formData.append(
                        "doc_five",
                        this.verification.doc_five ? this.verification.doc_five : ""
                    );
                    formData.append(
                        "doc_six",
                        this.verification.doc_six ? this.verification.doc_six : ""
                    );
                    formData.append(
                        "doc_seven",
                        this.verification.doc_seven ? this.verification.doc_seven : ""
                    );
                    formData.append(
                        "doc_eight",
                        this.verification.doc_eight ? this.verification.doc_eight : ""
                    );
                    verificationService
                        .create(this.slug, formData)
                        .then(() => {
                            this.$emit("initOrganisation");
                            this.verifyDialog = false;
                            this.$snotify.success(
                                "Your verfication request has been submitted."
                            );
                        })
                        .catch(error => {

                        });
                }
            },
            getQuestionnaires() {
                questionnaireService
                    .getAll(this.slug)
                    .then(response => {
                        this.questionnaireData = response.data.data;
                    })
                    .catch(error => {

                    });
            },
            addQuestionnaire() {
                this.$v.questionnaire.$touch();
                if (this.$v.questionnaire.$error) {
                    setTimeout(() => {
                        this.$v.questionnaire.$reset();
                    }, 3000);
                } else {
                    this.questionnaire.is_active = 0;
                    questionnaireService
                        .create(this.slug, this.questionnaire)
                        .then(response => {
                            (this.questionnaire = {
                                title: null
                            }),
                                this.$snotify.success("Questionnaier added successfully");
                            this.$v.questionnaire.$reset();
                            this.getQuestionnaires();
                        })
                        .catch(error => {

                        });
                }
            },
            editQuestionnaire(item) {
                this.questionnaire = item;
                this.questionnaireUpdate = true;
            },
            updateQuestionnaire() {
                this.$v.questionnaire.$touch();
                if (this.$v.questionnaire.$error) {
                    setTimeout(() => {
                        this.$v.questionnaire.$reset();
                    }, 3000);
                } else {
                    this.questionnaire.is_active = 0;
                    questionnaireService
                        .update(this.slug, this.questionnaire.id, this.questionnaire)
                        .then(response => {
                            (this.questionnaire = {
                                title: null
                            }),
                                this.$snotify.success("Questionnaier added successfully");
                            this.questionnaireUpdate = false;
                            this.getQuestionnaires();
                        })
                        .catch(error => {

                        });
                }
            }
        },
        computed: {
            nameErrors() {
                const errors = [];
                if (!this.$v.organisation.title.$dirty) return errors;
                !this.$v.organisation.title.required && errors.push("Name is required.");
                return errors;
            },
            established_year() {
                return this.organisation.attribute.established_year;
            },

            emailErrors() {
                const errors = [];
                if (!this.$v.organisation.email.$dirty) return errors;
                !this.$v.organisation.email.email && errors.push("Must be valid e-mail");
                !this.$v.organisation.email.required && errors.push("E-mail is required");
                return errors;
            },
            phoneErrors() {
                const errors = [];
                if (!this.$v.organisation.phone.$dirty) return errors;
                !this.$v.organisation.phone.required && errors.push("Phone is reuqired");
                !this.$v.organisation.phone.isPhoneNumner &&
                errors.push("Phone must be valid");
                return errors;
            },
            getEndDate() {
                var endDate = new Date(
                    this.newDate.getFullYear(),
                    this.newDate.getMonth()
                );
                return endDate.toISOString().slice(0, 10);
            }
        }
    };
</script>


<style>
    .preview {
        border: solid 1px green;

    }

    .white-background {
        background-color: white !important;
        border: solid 1px #eee;
    }

    .upload-example-cropper {
        border: solid 1px #eee;
        height: 250px;
        width: 100%;
    }

    .vue-square-handler {
        background: turquoise !important;
    }

</style>
